.tc-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  /* background-color: #669ecdeb; */
  background-color: #F8F9F9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.035px;
  color: #727478;
  padding: 16px;
  width: 243px;
}

.tc-item {
  display: flex;
  justify-content: space-between;
}

.tc-side {
  font-weight: 600;
  color: #727478
}