.file-input-container {
    position: relative;
    height: 40px;
    border: 1px solid #EAEBEC;
    border-radius: 5px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.file-details-container {
    height: 40px;
    border: 1px solid #EAEBEC;
    border-radius: 5px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.file-input-container input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.file-input-container::before {
    content: 'Add Images (max 5 MB.)';
    color: black;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    font-family: "Source Sans Pro";
    font-weight: 500;
    font-size: 14px;
}

@media (max-width:450px) {
    /* .file-input-container::before {
        content: 'Attach Image (max 5 MB.)';
        font-size: 12px;
    } */
}

.file-input-container::after {
    color: black;
    content: '\1F4CE';
    position: absolute;
    right: 10px;
    pointer-events: none;
}

.file-display {
    display: flex;
    align-items: center;
    width: 100%;
}

.file-icon {
    font-size: 20px;
    margin-right: 10px;
}

.file-name {
    flex-grow: 1;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #333;
}

.file-size {
    margin-left: 10px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #888;
}

.remove-button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.submit-btn-design {
    width: 545px;
    height: 40px;
    background-color: #404040;
    border-radius: 5px;
    padding: 10px 16px 10px 16px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 14px
}

.sr-form {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F9F9F9;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sr-form input,
.sr-form textarea,
.sr-form-submit,
.file-input-container,
.file-details-container {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.sr-form input:disabled{
    background-color: rgba(114, 116, 120, 0.12) !important;
}

.sr-form section {
    width: 545px;
    background-color: #FFFFFF;
    padding: 24px;
    border-radius:16px;
    box-shadow: rgba(184, 184, 184, 0.1);
}

.sr-form form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}


.sr-form section textarea {
    background-color: white !important;
}

.sr-form section textarea::placeholder,
.sr-form section input::placeholder {
    font-size: 14px;
}

@media (max-width:650px) {
    .sr-form {
        width: 500px;
    }

    .sr-form input,
    .sr-form textarea,
    .sr-form-submit,
    .sr-form section,
    .file-input-container,
    .file-details-container {
        width: 100%;

    }
}

@media (max-width:550px) {
    .sr-form {
        width: 100%;
        padding: 15px;
    }
}

.sr-form-title-wrapper {
    padding: 10px;
    box-shadow: rgba(184, 184, 184, 0.1);
    padding: 20px;
}
.sr-form-title-wrapper-image{
    border-bottom: 1px solid #E1E1E1;
}
.sr-form-title-wrapper img {
    width: 80px;
    margin-bottom: 5px;
}

.sr-form-title-wrapper .sr-form-title {
    /* margin-top: 20px; */
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 25px;
    line-height: 10px;
    color: #36383C;
}

.sr-form-title-wrapper .sr-form-p {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px !important;
    margin-top: 13px;
    color: #727478;
}

@media (max-width: 550px) {
    .sr-form-title-wrapper .sr-form-title {
        font-size: 18px;
    }

    .sr-form-title-wrapper .sr-form-p {
        font-size: 15px;
    }
}

@media (max-width:350px) {
    .sr-form-title-wrapper .sr-form-title {
        font-size: 15px;
    }

    .sr-form-title-wrapper .sr-form-p {
        font-size: 15px;
    }
}

.sr-form-textarea-wrapper{
    border: 1px solid #EAEBEC;
    padding: 10px 14px;
    font-family: Source Sans Pro;
    font-weight: 400px;
    border-radius: 8px;
}
.sr-form-textarea-wrapper textarea{
    width: 100%;
    height: 130px;
    padding: 5px;
    border: none;
    outline: none;
}
.sr-form-textarea-wrapper textarea:focus{
    border: none !important;
}

.labels-wraper{
    display: flex;
    gap: 12px;
    margin-top: 10px;
    flex-wrap: wrap;
}
.labels-wraper button{
    padding: 4px;
    font-size: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: rgba(157, 160, 166, 0.08);
    border-radius: 4px;
}

.labels-wraper button.selected{
    background-color:rgba(68, 114, 184, 0.12);
    color: #4472B8;
}