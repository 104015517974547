.ui-card{
    border-style: solid ;
    border-color: #eeeeee ;
    border-width: 1px ;
    border-radius: 0.7em ;
    background-color: white;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
    padding:15px 20px ;
}

