.create-from{
    color: #F63854;
    cursor: pointer;
}
.create-from:hover{
    color: #007bff;
}
  .meter-card{
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.7em !important;
    box-shadow: none !important;
}

.select-meter{
    color: black !important;
    font-size: 16px !important;
    font-weight: normal !important;
    height: 40px !important;
    padding-left: 10px !important;
}

.trigger-div{
    width: fit-content;
    margin-left: auto;
}

.dropdown-menu-active{
    margin-top: 8px;
    padding: 0;
    display: block;
    position: absolute;
    z-index: 1000000;
}

.selectCreated {
    color: #f63854 !important; 
    font-size: 16px !important;
    font-weight: bolder !important;
    height: 40px !important;
    padding-left: 10px !important;
}
.dropdown-menu-active li{
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px;
}
.dropdown-menu-active li:hover{
    background-color: #eee;
}
.assetPdf{
    width: auto;
    height: 50vh;
    margin: -2rem 2rem !important;
    font-size: large;
}
.assetrow{
    border-bottom: 1px solid gray;
    height: 3rem;
    text-align: start;
    padding-top: 0.5rem;
}
.pdfheader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 5px solid black;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-top: 30px;
}
.signature{
border-top: 1px solid black;
display: inline-block;
position: absolute;
width: 200px;
}
.firstHalf{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.firstHalf .right{
width: 50%;
}
.firstHalf .left{
width: 50%;
}
.main-border{
    border: 1px solid #535353;
}

.main-border .header-of-feature{
    border-bottom: 1px solid #535353;
    padding: 5px 10px;;
}
.main-border .header-of-feature h6{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}
.body-of-feature{
    padding: 15px 20px;
}

.totalcostbody{
    display: flex;
    justify-content: space-around;
}
.total{
    display: flex;
    justify-content: space-around;
}

.wtree ul:not(:first-of-type){
    margin-top: -7px
}

.wtree ul:nth-of-type(2){
    margin-left: 30px;

}
.wtree ul:nth-of-type(3){
    margin-left: 60px;

}
.wtree ul:nth-of-type(4){
    margin-left: 90px;
}


@media print {
    @page {
      size: auto; 
      margin: 20mm 0 15mm;
    }
  }

.tck-overlay {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 10000;
}

.search-templates{
    width: 250px !important;
    margin-top: -45px;
    background-color: #f8f8f8;
    padding: 5px;
}
.search-templates-bar{
    background-color:#f8f8f8 ;
    box-shadow: none;
}
.temp-content {
    width: auto;
}

.sigContainer {
    background-color: #fff;
    height: 200px;
    border-radius:10px ;
  }
  
  .sigCanvas {
    width: 100%;
    height: 100%;
  }
  
  .sign-image{
    width: 100%;
    height: 200px;
    margin: 20px auto;
    object-fit:fill;
    box-shadow: 0 0 10px #ccc;
    border-radius: 10px;
  }
  
  .sign-image h3 {
    text-align: center;
    color:#858585;
  }

  .nosign-image{
    margin: 20px 0;
    text-align: center;
  }

  .ptwcards {
    box-shadow: 0 0 10px #ccc;
    border-radius: 10px;
    margin-top: 20px;
    transition: all ease-in-out 0.3s;
  }
  
  .ptwcards:hover {
    transform: scale(1.04);
  }

  .cost-status{
    --inactive-bg: #cfd8dc;
    --active-bg: #F63854;
    --size: 1rem;
    appearance: none;
    width: calc(var(--size) * 2.2);
    height: var(--size);
    display: inline-block;
    border-radius: calc(var(--size) / 2);
    cursor: pointer;
    background-color: var(--inactive-bg);
    background-image: radial-gradient( circle calc(var(--size) / 2.1), #fff 100%, #0000 0 ), radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
    background-repeat: no-repeat;
    background-position: calc(var(--size) / -1.75) 0;
    transition: background 0.2s ease-out;
    margin-right: 10px;
  }
  .cost-status:checked{
    background-color: var(--active-bg);
    background-position: calc(var(--size) / 1.75) 0;
  }



label.filebutton {
    /* overflow:hidden; */
    position:relative;
    margin-top: 12px !important;
    cursor: pointer;
}

.filebutton span input {
    z-index: 999;
    line-height: 0;
    position: absolute;
    top: 0px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    margin: 0;
    padding:0;
}
.wrapper-of-attach{
    position: relative;
}
.wrapper-of-attach .attach-name{
    position: absolute;
    bottom: -10px;
    width: 96px;
    font-size: 12px;
    left: -15px;
}

.filters-wrapper{
    display: flex;
    gap: 16px;
    /* justify-content: space-between; */
    align-items: end;
    flex-wrap: wrap;
}

.wo-filter-wrapper .filter-wrapper {
    width: 100%;
}

.filter-wrapper{
    width: 14%;
}
.erase-wrapper{
    width: 10%;
    margin-top: 10px;
}
@media (max-width:1250px) {
    .filter-wrapper{
        width: 15%;
    }
    .erase-wrapper{
        width: 6%;
    }
}

@media (max-width:1000px) {
    .filter-wrapper{
        width:32%;
    }
    .erase-wrapper{
        width: 20%;
    }
}

@media (max-width:600px) {
    .filter-wrapper{
        width:48.5%;
    }
    .erase-wrapper{
        width: 20%;
    }
}

.erase-wrapper-analytics{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
}


.filters-wrapper-inv{
    display: flex;
    align-items: end;
    padding: 0 20px;
    flex-wrap: wrap;
}

.filter-wrapper-inv{
    width: 20%;
    margin-left: 10px;
}
.erase-wrapper-inv{
    width: 10%;
    margin-left: 10px;
    margin-top: 10px;
}
@media (max-width:900px) {
    .filter-wrapper-inv{
        width:25%;
    }
    .erase-wrapper-inv{
        width: 15%;
    }
}

@media (max-width:600px) {
    .filter-wrapper-inv{
        width:47%;
    }
    .erase-wrapper-inv{
        width: 20%;
    }
}


@media (max-width:380px) {
    .filter-wrapper-inv{
        width:100%;
    }
    .erase-wrapper-inv{
        width: 100%;
    }
}


.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

input[type="file"] {
    margin-bottom: 10px;
}

.the-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 650px;  /* Fixed width */
    height: 600px;  /* Fixed height */
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 100000000000000000000;
}

.modal--open {
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.modal--open {
    animation: fadeIn 0.3s forwards;
}

.modal--close {
    animation: fadeOut 0.3s forwards;
}

.the-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.overlay--open {
    opacity: 1;
}

.overlay--close {
    opacity: 0;
}

.the-modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-button {
    background: none;
    border: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.image-preview {
    width: 100%;
    height: 380px;
    object-fit: contain;
    border-radius: 15px;
    margin-bottom: 10px;
}

.caption-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px; 
    border: 1px solid #E1E1E1;
    background-color: white;
    position: relative;
    z-index: 10; /* Ensure it is on top */
    padding-top: 5px;
}

.caption-input,
.caption-input:hover,
.caption-input:focus {
    width: 100%;
    padding: 10px !important;
    border-radius: 20px !important;
    border: none !important;
    outline: none;
    background-color: white !important;
    /* align-self: center; */
    box-shadow: none !important;
    max-height: 100px;
    height: 44px;
    resize: none;  /* Disables grab/resize control */
    overflow: hidden;
    font-size: 14px;
}


.send-button {
    /* width: 40px;
    height: 40px;
    background-color: #F63854;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    align-self: center;
    margin-top: 10px; */
    align-self: flex-end;
    margin-bottom: 9px;
}

.send-button img {
    width: 39px;
    height: 28px;
}

.uploads-container {
    margin-top: 20px;
    padding: 10px;
    padding-top: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) ;
    gap: 20px;
}

.upload-item {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    /* width: 200px;
    height: 180px; */
    overflow: hidden;
    text-align: center;
}

.upload-file{
    width: 100%;
    height: 110px;
    object-fit: contain;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 5px;
}
.delete-upload-item, .edit-upload-item{
    position: absolute;
    top: 0;
    right: -100px;
    transition: 0.3s padding ease-in-out;
}

.upload-item:hover .delete-upload-item{
    top: 3px;
    right: 8px;
}
.upload-item:hover .edit-upload-item{
    top: 3px;
    right: 38px;
}

.delete-upload-item:hover , .edit-upload-item:hover{
    padding: 3px 5px;
    border-radius: 5px;
    background-color: #ccc;
}


.upload-file {
    font-weight: bold;
    margin-bottom: 5px;
}

.upload-caption {
    font-weight: 400;
    text-align: left;
}

.the-file-input {
    display: none;
}

.the-file-input-label {
    color: rgb(53, 45, 45);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    font-size: 10px;
    margin-bottom: 6px  !important;
    align-self: flex-end;
}

.wrapper-new-croper{
    position: fixed;
    top: 0%;
    height: 100vh;
    width:100vw;
    left: 0;
    z-index: 10000000000000000;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
.new-croper{
    position: fixed;
    top: 20%;
    height: 70vh;
    width:100vw;
    left: 0;
    z-index: 10000000000000000000;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

/* .file-input-label:hover {
    background-color: #0056b3;
} */
.checklist-drop-down{
    margin-top: 15px;
}

.select-all{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkbox-wrapper-title{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.checkbox-wrapper-title input{
    margin-right: 10px;
    width: 20px;
}

.checkbox-wrapper-title input:checked{
    background-color: #535353;

}

.tasks-wraper{
    background-color: #F3F4F5;
    border-radius: 12px ;
    padding: 8px 0px;
}

.tasks-wraper-task{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    padding: 4px 16px;
}

.tasks-wraper-task .task-name{
    font-size: 14px;
    font-weight: 500;
    width: 90%;
}

.tasks-wraper-task .task-name input{
    background-color: #F3F4F5 !important;
    box-shadow: none !important;
    border: none !important;
    outline: 0;
    flex-grow:1;
    border-radius: 0;
    width: 100%;
}
.add-task-input{
    display: flex;
    padding: 4px 16px;
}

.add-task-input input{
    background-color: #F3F4F5 !important;
    box-shadow: none !important;
    border: none !important;
    outline: 0;
    flex-grow:1;
}

.add-task-input input:hover,
.add-task-input input:focus{
    background-color: #F3F4F5 !important;
    border: none;

}


.checkbox-wrapper{
    display: flex;
}

.Checklist-Searching-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 15px;
    height: 42px;
    position: relative;
    z-index: 100000;
}

.Checklist-Searching-box input{
    background-color: #fff !important;
    box-shadow: none !important;
    border: none !important;
    outline: 0;
    flex-grow:1;
    height: 30px;
}
.Checklist-Searching-box input::placeholder{
    color: #ADADAD;
    font-size: 14px;
}

.Checklist-Searching{
    position: relative;
}

.Checklist-Searching-results{
    position: absolute;
    top: 54px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
    /* box-shadow:-8px 16px 32 0 rgba(0, 0, 0, 0.16); */
    box-shadow: 5px 10px 8px 10px rgba(0, 0, 0, 0.16);
    padding: 10px 15px;
    border-radius: 10px;
    z-index: 10000000;
}
.Checklist-Searching-results .Checklist-Searching-results-result{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
}
.Checklist-Searching-results .Checklist-Searching-results-result h6{
   margin-bottom: 0;
}

.Checklist-Searching-results-result:hover{
    background-color: #f1f1f1;
    border-radius: 10px;
    cursor: pointer;
}


.adjust-group{
    margin-bottom: 10px;
    padding: 10px 15px;
    cursor: auto;
    display: flex;
    align-items: center;
  }


  .icon-preview{
    padding: 7px;
    border-radius: 5px;
  }
  .icon-preview-active{
    background-color: #f6d2d7;
    padding: 7px;
    border-radius: 5px;
  }

  .task-preview-container{
    background-color: #F3F4F5;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 8px 15px;
}

  .checklist-attachment-wrapper{
    display: flex;
    margin-top: 10px;
  }


.task-preview-container textarea{
    height: 120px;
    width: calc(100% - 130px);
    background-color: white !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

  .file-upload-task-preview  {
    border: 2px dashed #d8d7d7;
    /* padding: 20px; */
    text-align: center;
    border-radius: 10px;
    background-color: #F3F4F5;
    transition: background-color 0.3s ease;
    /* margin: 8px 10px; */
    cursor: pointer;
    font-weight: normal;
    height: 120px;
    width: 120px;
    margin-right: 10px;
  }

  .without-border{
    border: none;
  }


  .file-upload-task-preview.dragging {
    background-color: #e0f7fa;
    border-color: #4fc3f7;
  }
  
  .file-upload-task-preview:hover {
    background-color: #f0f0f0;
  }
  
  .file-upload-task-preview label {
    display: block;
    font-size: 12px;
    color: #999;
    cursor: pointer;
    font-weight: normal !important;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .file-upload-task-preview label img{
    height: 120px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .file-upload-task-preview .attached-div{
    display: block;
    font-size: 14px;
    color: #999;
    cursor: pointer;
    font-weight: normal !important;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .file-upload-task-preview .attached-div .attached-img{
    height: 120px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  

  .wrapper-no-checklist{
    height: calc(100vh - 300px);
    width: 100%;
    position: relative;
  }

  .no-checklist{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.Status-Type{
    margin-right: 16px;
    position: relative;

}
  .Status-Type-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    padding: 5px 8px;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    cursor: pointer;
    color: #999999;
    background-color: white;
    
  }
  .Status-Type-results{
    position: absolute;
    bottom: 70px;
    left: -164px;
    background-color: white;
    width: 271px;
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 10px rgba(0, 0, 0, 0.16);
    z-index: 1000000001;
  }

  .Status-Type-results-result{
    padding: 5px 7px;
  }
  .Status-Type-results-result:hover{
    background-color: #f1f1f1;
    cursor: pointer;
  }
  .Status-Type-results-result h6{
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
  }
  .Status-Type-results-result:hover h6{
    color: #f63854;
  }
  .Status-Type-results-result span{
    font-size: 12px;
    color: #ADADAD;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }

  .Status-Type-results-result:hover span{
    color: black;
  }

  .rotateIcon {
    transform: rotate(180deg);
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-custom {
    background-color: black;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 70vh;
  }

  .close-modal{
    position: absolute;
    color: white;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  
  .original-size-image {
    max-width: 45vw;
    max-height: 50vh;
    object-fit: contain;
    border-radius: 10px;
  }


  @media (max-width:800px) {
    .modal-content-custom{
        width: 90vw;
        height: 60vh;
      }

      .original-size-image {
        max-width: 80vw;
        max-height: 55vh;
      }
  }


  .page-profile-footer-edit-wo{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    background-color: #f8f8f8;
    flex-wrap: wrap;
}

@media (max-width:450px) {
    .date-wo{
        display: none;
    }
}

.decline-msg{
    background-color: rgba(217, 49, 74, 0.16);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #D9314A;
    font-family: "raleway";
}
.decline-msg h4{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.decline-msg h6{
    font-size: 14px;
    font-weight: 500;
}
.decline-msg p{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: normal;
}

.accept-btn, .decline-btn{
    color: white;
    background-color: #22C684;
    border-radius: 8px;
    padding: 8px 12px;
}
.decline-btn{
    background-color: rgb(255, 251, 251);
    color: #D9314A ;
}
.decline-btn:hover{
    background-color: rgb(223, 221, 221);
}

.accept-btn:hover{
    background-color: #64cba1;
}

.custom_tabs .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }
  
  .custom_tabs .nav-link.active {
    background-color: #f8f9fa;
    color: #007bff;
    border-bottom: 2px solid #007bff;
  }
  
  .tab-title {
    margin-right: 2rem;
  }
  
  .info-icon {
    margin-left: 0.25rem;
    color: #ccc;
    font-size: 1.1rem;
  }
  
  
  .custom_tabs .nav-link:hover .info-icon {
    color: #ccc;
  }

  /* Custom tooltip styling */
.custom-tooltip .tooltip-inner {
    background-color: white; /* Change tooltip background */
    color:  rgba(77, 77, 77, 1);
    font-size: 12px; /* Adjust font size */
    padding: 10px 15px; /* Add padding */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 12px 32px 0px rgba(41, 43, 47, 0.16);
    font-family: "raleway";
    font-weight: 400;
}
  
/* Custom arrow styling to match white background */
.custom-tooltip .arrow::before {
    border-top-color: #fff; /* White arrow for top tooltips */
  }
  
  .custom-tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #fff; /* White arrow for top placement */
  }
  
  .custom-tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #fff; /* White arrow for bottom placement */
  }

.wo-approval h6{
font-weight: 600;
font-size: 16px;
}

.wo-approval p{
font-weight: 400;
font-size: 14px;
}

.wo-approval button{
    font-size: 14px;
    font-weight: 600;
}
.wo-approval .decline-btn{
color: rgba(54, 56, 60, 1);
}

.wo-approval-accepted-msg{
    background-color: #b1f1d8;
    border-radius: 8px;
    padding: 12px 12px 12px 16px;
    font-size: 14px;
    color: #20bb7d;
    font-weight: 600;
    border: 1px solid #20bb7d;
    
}
.wo-approval-declined-msg{
    background-color: #f9dde1;
    border-radius: 8px;
    padding: 12px 12px 12px 16px;
    font-size: 14px;
    color: #d9314a;
    font-weight: 600;
    border: 1px solid #d9314a;
}

.filters-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filters-left-wrapper{
    width: 50%;
    display: flex;
    align-items: center;
    
}

.filters-left-wrapper > div{
    margin-right: 10px;
    width: 40%;
}

.filters-left-wrapper > button{
    align-self: end;
}
.filters-right-wrapper{
    align-self: end;
    display: flex;
    align-items: center;
}

.filters-right-wrapper button{
    margin-right: 10px;
}

.black-button{
    color:#F8F9F9 ;
    padding: 8px 16px;
    background-color: #36383C;
    border-radius: 8px;
}


.wo-tabs{
    padding: 10px;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
}

.wo-tabs .tab-content{
    width: 600px;
}

.wo-tabs .wo-comment-tab{
    width: 320px;
    border-left: 1px solid #ccc;
    padding: 10px;
}



.comment-section{
    width: 100%;
    padding: 10px;
}

.comment-section-add-comment {
    display: flex;
    align-items: flex-start;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    /* padding: 0px 0px 0px 5px; */
    margin-bottom: 15px;
    /* height: 42px; */
    position: relative;
}

.comment-section-comment-icon{
    width: 39px;
    height: 28px;
    margin-bottom: 6px;
}

 .comment-section-comment-input{
    flex: 1;
}

.comment-section-comment-input > div{
    width: 100%;
}

.comment-section-comment-input > div div{
    width: 100%;
    background-color: transparent !important;
} 

.comment-section-comment-input > div div textarea{
    width: 100%;
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    /* padding-right: 10px;  */
}

.comment-section-comment-icon {
    margin-left: 5px;
    cursor: pointer;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    align-self: flex-end;
}

.suggetion-list-ui{
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    background-color: white;
    /* padding: 8px 16px; */
    border-radius: 8px;
    border: 1px solid #E1E1E1;
    max-height: 350px;
    overflow-y: auto;


}

.suggetion-list-ui .suggetion-item{
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;

}
.suggetion-list-ui .suggetion-item:hover{
    background-color: #007bff;
    color: white;
}


.comment-section-comment-container {
    margin-bottom: 15px; 
    transition: all 0.3s ease-in-out;
}

.comment-section-upper-part {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
}

.comment-section-upper-part-left{
    display: flex;
    align-items: center;
}

.comment-section-upper-part-right{
    /* cursor: pointer; */
    position: relative;
}

.comment-section-upper-part-right svg{
    font-size: 15px;
}

.comment-tools{
    position: absolute;
    top: calc(100% + 2px);
    right: -5px;
    background-color: white;
    border-radius: 8px;
    width: 150px;
    border: 1px solid #E1E1E1;
    z-index: 100000000000;
}
.comment-tools li{
    padding: 8px 16px;
    cursor: pointer;
}


.comment-tools li:hover{
    background-color: #E1E1E1;
}

.comment-section-avatar {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}

.comment-section-comment-content {
}

.comment-section-name {
    font-weight: 600;
}

.comment-section-role {
    color: #757980;
    font-size: 12px;
}

.comment-section-comment-text {
    margin-top: 5px;
}

.comment-section-mention {
    font-size: 14px;
    color: #4A4C50;
}

.mention-highlight {
    color: var(--primary-color);
}


.comment-section-timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.comment-section-show-all {
    font-size: 13px;
    color: #888;
    margin-top: 20px;
    /* text-align: center; */
}




.comment-section-edit{
    /* transform: translateY(-20px); */
    background-color: white;
    border: 1px solid #E1E1E1;
    padding: 16px 8px;
    border-radius: 8px;
}
.comment-section-edit-wrapper{
    position: relative;
    z-index: 100000000000000;
}

.comment-section-edit-buttons{
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}
.comment-section-edit-button:first-of-type{
    margin-right: 8px;
}

.comment-section-edit-button:hover{
    color: #007bff;
}

.comment-button{
    background-color: #F8F9F9;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
}

.comment-button.active{
    color: white;
    background-color: var(--primary-color);
}

.comment-button:hover{
    background-color: #E1E1E1;
}

.comment-button.active:hover{
    background-color: var(--primary-color);
}


@media (max-width : 400px) {
    .comment-button-text{
        display: none;
    }
}



.create-wo-from-pm{
    font-size: 14px !important;
    color: black !important;
    font-weight: 500;
}

.create-from{
    font-size: 14px !important;
    font-weight: 500;
    /* margin-left: 10px; */
}