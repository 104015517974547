.card{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2) !important;
    margin-bottom: 1rem
}
.card-header1{
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}
.card-body1{
    flex:1 1 auto !important;
    min-height: 1px !important;
    padding: 1.25rem !important;
}
.center {
    display: block !important;
    text-align: -webkit-center !important;
}
label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 700;
}