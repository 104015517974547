.scrollX {
  overflow-x: auto; /* Only show scrollbar if content overflows */
  scrollbar-gutter: stable; /* Stabilizes the layout when scrollbar appears */
}

/* Style the scrollbars */
.scrollX::-webkit-scrollbar {
  height: 10px;
  background-color: transparent; /* This hides the track */
}

.scrollX::-webkit-scrollbar-thumb {
  /* background: rgba(100, 100, 100, 0.5);
  border-radius: 5px; */
  background-color: transparent; /* This hides the track */

}

.scrollX::-webkit-scrollbar-track {
  background-color: transparent; /* Hides the scrollbar track */
}

.scrollX {
  scrollbar-color: rgba(157, 160, 166, 0.32); /* For Firefox */
  scrollbar-width: thin;
}

.table {
  border: 1px solid black;
  width: 100%;
  border: none;
  border-collapse: separate;
  border-spacing: 0 10px;
  /* padding: 10px; */
  /* background: #f8f8f8 !important; */
  overflow: hidden;
  border-radius: 8px !important;
  white-space: nowrap ;
  padding-bottom: 20px;
}


.table thead {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  background-color: #F2F2F7 !important;

}

/* .forScroll thead th:nth-child(1),
.forScroll thead th:nth-child(2),
.forScroll tbody td:nth-child(1),
.forScroll tbody td:nth-child(2){
  position: sticky;
  left: 0;
  background-color: white; 
  z-index: 1;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}


.forScroll thead th:nth-child(2),
.forScroll tbody td:nth-child(2){
  z-index: 2;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
} */

.table thead th {
  font-weight: normal !important;
  color: #36383C;
  text-align: left;
}

.table thead th {
  padding: 15px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 500 !important;
}

.table tbody tr td {
  padding: 7px 15px;
  /* padding: 0 15px; */
  height: 42px;
  font-size: 14px;
  text-align: left;
  font-weight: 500 !important;
}

.table tbody tr:hover {
  cursor: pointer;
  visibility: visible;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 5px #ededed;
}

.table tbody tr td svg {
  cursor: pointer;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

td:first-child {
  border-right-style: solid;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.editBtn {
  opacity: 1;
  color: #F63854
}

.addChild {
  color: #F63854;
}

.addChild:hover {
  color: #007bff;
}

.noResults {
  text-align: center !important;
  font-size: 24px !important;
}


.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  padding: 5px;
  /* background-color: white; */
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 30px;
}
.overlay {
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10000;
}
.jumpToBageWrapper{
  position: absolute;
  top: -80px;
  right: 50px;
  padding: 15px 10px;
  background-color: white;
  border-radius: 15px;
  z-index: 100000;
}
.jumpToBageWrapper input{
  width: 60px;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.jumpToBageWrapper button{
  background-color: #f8f8f8;
  margin-left: 5px;
  color: #DB1F48;
  border-radius: 10px;
}


.pagination .select {
  width: 182px;
  /* background-color: #F8F9F9 !important; */
  padding: 8px;
  border-radius: 8px;
  color: black !important;
  font-weight: normal !important;
  font-size: 16px !important;
  height: 40px !important;
  cursor: pointer;
  border: 1px solid #E1E1E1 !important;
}

.pagination .selectChecklist{
  background-color: white !important;
  width: 182px;
  background-color: #f8f8f8;
  padding: 5px 10px;
  border-radius: 0;
  color: black !important;
  font-weight: normal !important;
  font-size: 16px !important;
  height: 40px !important;
  cursor: pointer;
}

.pagination .buttons {
  /* background-color: #E1E1E1; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;

}

.pagination .buttons button, 
.jumpToBageWrapper button{
  padding: 10px 5px;
}

.pagination .buttons button:hover , 
.jumpToBageWrapper button:hover {
  color: #F63854;
}

.pagination .buttons button:disabled, 
.jumpToBageWrapper button:disabled {
  color: gray;
}

.pagination .buttons span {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 136px;
  /* box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  font-weight: 500;
  border: 1px solid#E7E7E7 ;
}
.pagination .buttons span svg {
  margin-top: 4px;
}

@media (max-width :550px) {

  .pagination {
    flex-direction: column;
  }

  .pagination .select {
    margin-bottom: 10px;
    width: 182px;
  }

  .pagination .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
  }

  .pagination .buttons button {
    padding: 5px 10px;
  }

  .pagination .buttons button:hover {
    color: #F63854;
  }

  .pagination .buttons button:disabled {
    color: gray;
  }

}

.filterTable {
  width: 400px;
  box-shadow: 0 0 10px #a7a7a7;
  border-radius: 10px;
  margin: 20px 0;
  background-color: white;

}

.filterTable .wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.filterTable .wraper h4 {
  font-size: 18px;
  margin-bottom: 0 !important;
  margin-left: 15px !important;
}

.filterTable .checkboxs {
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #F1F1F1;
  padding: 10px 15px;
}

.filterTable .checkbox {
  margin-left: 15px;
}

.filterTable .checkbox input {
  margin-right: 5px;
}

@media (max-width :550px) {
  .filterTable {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

  }

  .filterTable .wraper h4 {
    font-size: 16px;
  }

  .filterTable .checkboxs {
    display: flex;
    margin-top: 10px;
  }

  .filterTable .checkbox:not(:first-of-type) {
    margin-left: 15px;
  }

  .filterTable .checkbox input {
    margin-right: 5px;
  }

  .imports {
    display: flex;
    margin: 15px 0;
    flex-wrap: wrap;
  }

  .imports div {
    background-color: white;
    width: 50%;
    color: red;
    padding: 8px 10px;

  }

  .imports div:not(:first-of-type) {
    margin-left: 30px;

  }

  .imports div:hover {
    background-color: rgba(255, 0, 0, 0.2);
  }

  .imports div svg {
    margin: 0 5px;
  }

}

.open {
  color: #01949A;
  background-color: #01959a2d;
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}

.inProgress{
  color: #FD931B;
  background-color: #fd941b2a;
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}

.onHold{
  color: red;
  background-color: #97060630;
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}

.declined{
  color: #ED1B1B;
  background-color: rgba(237, 27, 27, 0.16);
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}
.pending{
  color: #787878;
  background-color: rgba(120, 120, 120, 0.16);
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}

/* .schedule {
  color: #01949A;
  background-color: #01959a2d;
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;
  width: 120px;
  display: inline-block;
  text-align: center;
} */

.completed {
  color: green;
  background-color: #49ea7f28;
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  width: 107px;
  display: inline-block;
  text-align: center;
}

.hPriority {
  color: #DB1F48;
  background-color: #db1f4827;
  border-radius: 25px;
}

.mPriority {
  color: #e0e000;
  background-color: #ffffe0;
  border-radius: 25px;
}

.lPriority {
  color: #006400;
  background-color: #98ff98;
  border-radius: 25px;
}

.hPriority,
.mPriority,
.lPriority {
  width: 80px !important;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  margin-right: 5px;
  overflow: hidden;
}

.pr {
  width: 80px !important;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  margin-right: 5px;

}

.category {
  font-size: 12px;
  padding: 4px;
  color: #232b2b;
  background-color: #c4c3d0;
  border-radius: 25px;
  display: inline-block;
  width: 80px !important;
  text-align: center;
  overflow: hidden;
}

.del-bt {
  opacity: 0;
}

.table tbody tr:hover .del-bt {
  opacity: 1;
}

@media (max-width :900px) {
  .labels {
    display: block;
    width: 80px;
    margin-bottom: 5px;
  }
}

.imagesWrapper {
  display: flex;
  min-width: 150px;
}

.assignedImage {
  border-radius: 50%;
  height: 25px;
  aspect-ratio: auto 25 / 25;
  width: 25px;
  margin-left: 0.3rem !important;
}

.newFilterTable{
  position: absolute;
  background-color: #F8F9F9;
  padding: 10px;
  width: 220px;
  box-shadow: -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  top: calc(100% + 5px);
  right: 10px;
  z-index: 10000000;
}

.tableProhressBar{
  width: 100%;
  height: 5px;
  background-color: #E1E1E1;
  border-radius: 5px;
  position: relative;
}

.tableProhressBar::after{
  content: "";
  position: absolute;
  left: 0;
  width: var(--progress-width);
  z-index: 100;
  height: 5px;
  background-color: var(--progress-color);
  box-shadow: var(--progress-shadow);
  border-radius: 5px;
} 


