
.banner-title{
    text-align: center;
    margin-bottom: 3px !important;
    font-family: 'Source Sans 3', 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.sub-text{
    color:#FFFFFF;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Source Sans 3', 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
}
.button-txt{
    font-family: 'Source Sans 3', 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}
.activeButton1{
    border-radius: 5px  !important;
    background-color:#404040 !important;
    color:white;
}

.buttonGroup{
    width: 113px;
    height: 100%;
}


.analytics-date-picker .rdrCalendarWrapper{
    width: 100% !important;

}

.analytics-date-picker .rdrMonthAndYearPickers{
    width: 100% !important;
}
.analytics-date-picker .rdrMonth{
    width: 100% !important;
}

.analytics-date-picker .rdrMonthAndYearPickers select{
    height: auto !important;
}

.analytic-cards-container{
    display: flex;
    gap: 24px;
    margin-top: 12px;
    flex-wrap: wrap;
}



// .wrape-pie-progress{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

// .wrape-pie-progress .chart-container{
//     width: 48%;
// }