.select-dash{
    color: black !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }

.select2-container .select2-selection--single {
    max-height: 65px !important;
}
.card-primary:not(.card-outline)>.card-header {
    background-color: rgb(245, 245, 245) !important;
    color: black !important;
}
.calender .fc-day-grid-event{
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
}
.calender .fc-col-header-cell-cushion{
    font-weight: bold;
}
.calender .fc-day-sun, .fc-day-sat{ color:rgb(255, 154, 154); }
.calender .fc .fc-daygrid-day.fc-day-today  {
    background: #fff5f5 !important;
    color: #F63854 !important;
    /* font-weight: bold !important; */
}
.calender .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
 color: white !important;
 background-color: #F63854;
 margin: 5px 5px 0 0 !important;
 border-radius: 5px;
 font-size: 16px !important;
 font-weight: 500 !important;
 padding: 0 0 10px 5px !important;
 height: 25px;
 width: 25px;
}
.calender .fc-toolbar.fc-header-toolbar{
  margin-bottom: 0
 !important
;
}
.calender .fc-other-month{
  background-color: rgba(245, 245, 245, 0.4) !important;
} 
.calender .fc th, .calender .fc td {
  border-style: none !important;
}
.calender .table-bordered thead td{
    border: none;
}

.calender .fc table {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    border-radius: 0.5em;
    font-size: 1em;
}
.calender .fc-timegrid-axis-frame , .calender .fc-timegrid-divider , .calender .fc-timegrid-slot-label-cushion,
 .calender .fc-scrollgrid-shrink-cushion , .calender .fc-timegrid-slot, .calender .fc-timegrid-slot-lane
// .fc-scrollgrid-section-body  fc-scrollgrid-section-liquid
{
    display: none !important;
}
.calender .fc-timegrid-now-indicator-container{
    background-color: white;
}

@media (min-width: 1251px) {
    .calender .fc-event {
        padding: 2px 8px !important;
        border-radius: 10px;
        font-size: 15px !important;
    }
}

@media (max-width: 1250px) {
    .calender .fc-event {
        padding: 2px 8px !important;
        border-radius: 10px;
        font-size: 13px !important;
    }
}


@media (max-width: 767px) {
    .calender .fc-event {
        padding: 2px 4px !important;
        font-size: 12px !important;
    }
}
@media (max-width: 650px) {
    .calender .fc-event {
        padding: 2px !important;
        font-size: 10px !important;
    }
}

.calender{
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.7em !important;
    box-shadow: none !important;
    background-color: white;
    padding: 20px;
}
.calender-wraper{
    padding:20px ;
}
@media (max-width: 575px) {
    .calender{
        padding: 0;
    }
    .calender .fc .fc-toolbar .fc-toolbar-chunk{
        margin-bottom: 10px;
    }
    .calender .fc .fc-toolbar .fc-toolbar-title{
        font-size: 20px;
    }
    .calender .fc .fc-toolbar .fc-toolbar-chunk button{
        font-size: 14px;
    }
}
.dash-title{
    font-size: 20px;
    margin-bottom: 20px;
}
.dash-title span{
font-weight: normal;
}

.open-ticket{
    color: #F63854;
    cursor: pointer;
}
.open-ticket:hover{
    color: #007bff;
}

.calender .fc-popover-body {
    max-height: 400px;
    overflow: scroll;
}

.calender .fc-direction-ltr .fc-daygrid-event.fc-event-start, .calender .fc-direction-rtl .fc-daygrid-event.fc-event-end{
    width: 100%;
}

// popover-body

.planner-outlined-button:hover {
    background-color: #f84e68 !important;
}

.planner_select_mode {
    border: 1px solid #E1E1E1 !important;
    width: fit-content;
    padding-left: 8px;
}
.maintenance-chart-dot{
    position: relative;
    cursor: pointer;
}

.maintenance-chart-tooltip{
    position: absolute;
    left: 25px;
    top: -36px;
    box-shadow: 0px 12px 32px 0px rgba(41, 43, 47, 0.16);
    background-color: white;
    width: 200px;
    z-index: 100;
    padding: 12px 13px;
    border-radius: 8px;
}

.maintenance-chart-tooltip-right{
    position: absolute;
    right: 25px;
    top: -36px;
    box-shadow: 0px 12px 32px 0px rgba(41, 43, 47, 0.16);
    background-color: white;
    width: 200px;
    z-index: 100;
    padding: 12px 13px;
    border-radius: 8px;
}
.maintenance-chart-tooltip-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.maintenance-chart-tooltip-header p{
    margin-bottom: 0px;
    font-size: 12px;
    color: #4D4D4D;
}

.maintenance-chart-tooltip-header p:first-of-type{
    font-size: 12px;
}
.maintenance-chart-tooltip-header p:last-of-type{
    display: flex;
    align-items: center;
}

.maintenance-chart-tooltip-title{
    margin: 5px 0;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}
.maintenance-chart-tooltip-date{
    text-align: left;
    color:#757980 ;
}