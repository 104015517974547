select {
  border: none !important;
  color: grey !important;
  font-weight: bold !important;
  height: 3em !important;
}

.card {
  border: none !important;
}

// .card-body {
//   padding-top: 0.5em;
//   background-color: #f8f8f8;
// }

th {
  font-weight: lighter;
}

.card-primary:not(.card-outline) > .card-header {
  background-color: #f8f8f8;
}

.card-primary:not(.card-outline) > .card-header,
.card-primary:not(.card-outline) > .card-header a {
  color: black;
}

.edit-category-title-inline {
  font-size: 17px !important;
}

.edit-category-card-inline {
  background-color: #f8f8f8;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0em;
  margin: 0em;
}

.edit-category-card-title-inline {
  font-size: 17px !important;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom_tabs{
  color:#F63854
}
.card-tools{
  color :#F63854
}
.card {
  border: none !important;
}
// @media only screen and (min-width: 590px) {
//   .title {
//     display: flex;
//   }
// }
.Work-order-validator {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Work-order-validator-left {
  width: 40%;
}

.Work-order-validator-left h5 {
  font-size: 16px;
}

.Work-order-validator-left p {
  font-size: 14px;
  color: #757980;
}

.Work-order-validator-right {
  width: 58%;
  padding: 24px;
  border-radius: 12px;
  background-color: rgba(157, 160, 166, 0.08);
}

.Work-order-validator-right table {
  width: 100%;
}

.Work-order-validator-right table tbody td{
  padding-bottom: 10px;
}

.Work-order-validator-right table tbody td:first-of-type {
  width: 30%;
  padding-right: 10px;
}

.Work-order-validator-right table tbody td:nth-child(2) {
  width: 30%;
  padding-right: 10px;
}

.Work-order-validator-right table tbody td:nth-child(3) {
  width: 15%;
  padding-right: 15px;
}

.Work-order-validator-right table thead td:nth-child(4),
.Work-order-validator-right table tbody td:nth-child(4) {
  width: 20%;
  text-align: right;
}

.Work-order-validator-right table td button{
  background-color: none;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  font-size: 12px;
  color: #36383C;
  font-weight: 600;
}

.Work-order-validator-right table thead td{
  font-size: 14px;
  color: #36383C;
  font-weight: 600;
  padding-bottom: 20px;
}

.Work-order-validator-actions {
  color: #f63854 !important;
  cursor: pointer;
}


.Work-Order-cutomization-button {
  background-color: none;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  font-size: 12px;
  color: #36383C;
  font-weight: 600;
}

.category-header, .category-body{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.category-header button{
  color: #f63854;
  font-weight: 500;
}
.category-header h4{
  color: #36383C;
  font-size: 14px;
  font-weight: 500;
}

.there-no-data{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #757980;
}