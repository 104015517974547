
.previntive-mantenance-feature{
    font-family: 'Source Sans Pro';
}

.add-wo {
    color: #F63854 !important;
    background-color: white !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 8px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.add-wo:hover {
    color: #F63854 !important;
    background-color: #FFCCD4 !important;
    border: 1px solid #F63854 !important;
}

.add-wo span {
    margin-right: 5px;
    font-weight: bold;
}

.meter-card {
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.7em !important;
    box-shadow: none !important;
}

.select-meter {
    color: black !important;
    font-size: 16px !important;
    font-weight: normal !important;
    height: 40px !important;
    padding-left: 10px !important;
}

.trigger-div {
    width: fit-content;
    margin-left: auto;
}

.dropdown-menu-active {
    margin-top: 8px;
    padding: 0;
    display: block;
}

.selectCreated {
    color: #f63854 !important;
    font-size: 16px !important;
    font-weight: bolder !important;
    height: 40px !important;
    padding-left: 10px !important;
}

.clicked-row {
    background-color: white;
    color: rgb(246, 56, 84);
}

.add-remove {
    background-color: rgb(255, 235, 238);
    color: rgb(246, 56, 84);
    padding: 10px !important;
}

tr.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.dropdown-menu-active li {
    cursor: pointer;
    font-size: 16px;
}

.dropdown-menu-active li :hover {
    background-color: #eee;
}

.assetPdf {
    width: auto;
    height: 50vh;
    margin: 2rem;
    font-size: large;
}

.assetrow {
    border-bottom: 1px solid gray;
    height: 3rem;
    text-align: start;
    padding-top: 0.5rem;
}

.pdfheader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 5px solid black;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-top: 30px;
}

.signature {
    border-top: 1px solid black;
    display: inline-block;
    position: absolute;
    width: 200px;
}

.firstHalf {
    display: flex;
    justify-content: space-between;
}

.totalcostbody {
    display: flex;
    justify-content: space-around;
}

.total {
    display: flex;
    justify-content: space-around;
}


@media (max-width: 580px) {
    .t-scroll {
        overflow-x: scroll;
        margin-bottom: 10px;
    }
}

.t-scroll table td {
    font-size: 15px !important;
}

.used-status {
    position: absolute;
    font-size: 12px;
    top: -22px;
    left: 42px;
}

.checkbox-column {
    display: none;
    /* Hide the original checkbox */
}

.checkbox-column+label:before {
    content: '';
    /* Empty content for unchecked state */
    font-size: 18px;
    /* Set the font size */
    color: white;
    /* Customize the color */
    background-color: #0075ff;
    /* Set the background color */
    border: 1px solid #ccc;
    /* Add a border */
    height: 18px;
    /* Customize the size */
    width: 18px;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
    margin-right: 10px;
}

.checkbox-column:checked+label:before {
    content: '\2713';
    /* Unicode checkmark character for checked state */
}

.checkbox-column:not(:checked)+label:before {
    background-color: white;
}

.checkbox-column:disabled+label:before {
    opacity: 0.8;
    /* Adjust opacity for disabled state */
}

.choose-assets-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
}

.choose-assets-location button {
    color: var(--primary-color);
    display: flex;
    align-items: center;
}


.card-add-new {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #E1E1E1;
    width: 100%;
    padding: 16px;
}

.heading-of-card-add-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.note-for-pm {
    border-radius: 8px;
    padding: 12px 12px 12px 16px;
    border: 1px solid #E1E1E1;
    background-color: #F2F2F3;
    color: #4A4C50;
    font-weight: 600;
}



.frq-content h5 {
    font-weight: 600;
    font-size: 16px;
    color: #36383C;

}

.repetition {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.repetition div {
    width: 24%;
    padding: 8px 16px;
    text-align: center;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
}
.repetition button {
    width: 24%;
    padding: 8px 16px;
    text-align: center;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
}

.repetition div.active-repetition {
    background-color: rgba(217, 49, 74, 0.16);
    color: #D9314A;
}
.repetition button.active-repetition {
    background-color: rgba(217, 49, 74, 0.16);
    color: #D9314A;
}

.repetition button:disabled{
    cursor: default;
}

.repeat-every {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.repeat-every-box {
    flex-grow: 1;
    border: 1px solid #E1E1E1;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    color: #9DA0A6;
}

.repeat-every-box input {
    flex-grow: 1;
    padding: 5px;
    padding-left: 10px;
    background-color: white !important;
    box-shadow: none;
    outline: 0;
    border: none !important;
    color: black;
}

.repeat-every button {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    margin-left: 10px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.note-for-weekend {
    display: flex;
    font-size: 14px;
    font-style: italic;
    color: #36383C;
    font-weight: 400;
}

.note-for-weekend span {
    font-style: normal;
    font-weight: bold;
    margin-right: 5px;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinner {
    -moz-appearance: textfield;
    /* Firefox */
}


.weekly-frequency-switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 22px;
}

.weekly-frequency-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.weekly-frequency-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9DA0A6;
    transition: .4s;
    border-radius: 25px;
}

.weekly-frequency-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    top: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 25px;
}

.weekly-frequency-switch input:checked+.weekly-frequency-slider {
    background-color: var(--primary-color)
}

.black-switch input:checked+.weekly-frequency-slider {
    background-color: #36383C
}

.weekly-frequency-switch input:checked+.weekly-frequency-slider:before {
    transform: translateX(10px);
}

.weekly-frequency-label {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
    font-weight: 600;
}


/* .attach-container {
    max-width: 500px;
    margin: 0 auto;
  } */

.pm-attach-container .attachments {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.pm-attach-container h6 {
    font-size: 16px;
    font-weight: 600;
}

.pm-attach-container-p {
    font-size: 14px;
    font-weight: 400;
}

.pm-attach-container .attachment-preview,
.pm-attach-container .add-more label {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #E1E1E1;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.pm-attach-container .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pm-attach-container .doc-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.pm-attach-container .doc-preview span {
    font-size: 24px;
}

.pm-attach-container .add-more label {
    background-color: white;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: dashed;
}

.pm-attach-container .add-more label {
    cursor: pointer;
}

.pm-attach-container .delete-btn {
    position: absolute;
    top: 5px;
    left: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: var(--primary-color);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.schedule-settings h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.schedule-settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.schedule-settings table {
    width: 100%;
    border-collapse: collapse;
}

.schedule-settings table thead tr {
    background-color: #F2F2F7;
    margin-bottom: 10px;
}

.schedule-settings table thead tr th {
    color: #36383C;
    font-weight: 500;
    font-size: 14px;
}

.schedule-settings table th,
.schedule-settings table td {
    padding: 8px;
    text-align: left;
}

.schedule-settings input[type="checkbox"] {
    margin-right: 5px;
    width: 17px;
    height: 17px;
    cursor: pointer;
    margin-top: 7px;
}
.schedule-settings input[type="checkbox"]:disabled{
    cursor: default;
}

.schedule-settings-name span:first-of-type,
.schedule-settings-date {
    font-size: 15px;
    font-weight: 500;
    color: #36383C;
    cursor: pointer;
}

.schedule-settings-date {
    cursor:text;
}

.schedule-settings-name span:last-of-type {
    font-size: 13px;
    font-weight: normal;
    color: #757980;
}

.schedule-settings-not-generated {
    font-size: 13px;
    font-weight: normal;
    color: #757980;
}

.schedule-settings .generate-btn {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #151617;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}

.schedule-settings .generate-btn:disabled{
    background-color: #F2F2F3;
    color:#C7C9CC ;
    cursor: not-allowed;
}

.activity-log h3 {
   font-size: 24px !important;
   font-weight: 600 !important;
   margin-bottom: 10px !important;
}

h3 {
    margin-bottom: 20px;
}

.log-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.log-item {
    display: flex;
    align-items: flex-start;
    /* margin-bottom: 20px; */
    position: relative;
}

.log-icon {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid rgba(217, 49, 74, 0.24);
}

.log-icon .dot {
    width: 12px;
    height: 12px;
    background-color: white;
    border: 2px solid #D9314A;
    border-radius: 50%;
    display: block;
}

.log-details {
    padding: 10px;
    width: 100%;
}

.log-header {
    font-weight: 400;
    margin-bottom: 5px;
    color: #757980;
    font-size: 12px;
}

.log-description {
    color: #36383C;
    font-weight: 600;
    font-size: 14px;

}

.log-item::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 14px;
    height: calc(100% );
    width: 4px;
    background-color: #d9d9d9;
}



.history-footer {
    font-size: 14px;
    color: #888;
    margin-top: 8px;
    display: flex;
    align-items: center;   
}

.history-log-icon{
    position: relative;
    margin-right: 10px;
}
.history-footer .history-log-icon-dot {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    display: block;
    border: 2px solid #999999;
    margin-left: 3px;
}


.pm-schedule-tabs{
    display: flex;
    align-items: center;
    position: relative;
}

.pm-schedule-tabs::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    bottom: 0px;
    background-color: #E1E1E1;
    z-index: 1;
}

.pm-schedule-tabs button{
    margin-right: 3px;
    padding: 8px 16px;
    border: 1px solid #E1E1E1;
    background-color: white;
    color: var(--primary-color);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 1px 1px 0px 1px ;
    z-index: 2;
    font-size: 14px;
}

.pm-schedule-tabs button.un-active{
    background-color: #E1E1E1;
    color: #9DA0A6;
}

.pm-schedule-tabs button span{
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.filters-left-wrapper-period{
    position: relative;
}

.custom-date-picker-wrapper{
    position: absolute;
    top: calc(100% + 10px);
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 0px;
    background-color: #fff;
    z-index: 100000;
    
}

.custom-date-picker-wrapper-2{
    top: calc(100% + 5px);
    left: -130px;
}
.custom-date-picker {
    display: flex;

  }
  .custom-date-picker-side-menu {
    width: 150px;
    margin-right: 20px;
    border-right: 1px solid #E1E1E1;

  }

  .menuList-for-date-range{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menuItem-for-date-range{
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    transition: background-color 0.3s ease;
    width: 150px;
}
.menuItem-for-date-range.active{
    color: #D9314A;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menuItem-for-date-range:not(:last-of-type){
    border-bottom: 1px solid #E1E1E1;
}
.menuItem-for-date-range:hover{
    background-color: #f5f5f5;
}
.date-range-picker{
    padding: 10px;
}
.custom-date-picker-wrapper .custom-date-picker-active-button  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #E1E1E1;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}



.custom-date-picker-wrapper .rdrCalendarWrapper{
    /* width: 100% !important; */
    width: 500px !important;
}
.custom-date-picker-wrapper .rdrMonthAndYearPickers{
    width: 100% !important;
}
.custom-date-picker-wrapper .rdrMonth{
    width: 100% !important;
}
.custom-date-picker-wrapper .rdrDayNumber span{
    color:#36383C !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-right: 10px !important;
    padding: 10px !important;
    
}
.custom-date-picker-wrapper .rdrInRange {
    color: #E3871E29 !important;
    text-align:  left !important;
    margin-right: 1px !important;
    padding: 10px !important;
    
}

.custom-date-picker-wrapper .rdrStartEdge,
.custom-date-picker-wrapper .rdrEndEdge {
    background-color: #E3871E !important;
    color: white !important;
    /* border-radius: 50%;
    width: 30px;
    height: 27px; */
}
.custom-date-picker-wrapper .rdrStartEdge + .rdrDayNumber {
    text-align: center;
}
.custom-date-picker-wrapper .rdrStartEdge + .rdrDayNumber span,
.custom-date-picker-wrapper .rdrEndEdge + .rdrDayNumber span  {
    /* background-color: #E3871E !important; */
    color: white !important;
    margin-left: 6px;
}

.custom-date-picker-wrapper .rdrMonthAndYearPickers select{
    height: auto !important;
}
.custom-date-picker-wrapper .rdrNextPrevButton{
    background: none;
}
.custom-date-picker-wrapper .rdrWeekDay{
    font-size: 14px;
    color: #757980;
    font-weight: 600;
}


.filter-wrapper-prioirty{
    width: 144px;
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(157, 160, 166, 0.12);
}
.filter-wrapper-prioirty label{
    margin-bottom: 0 !important;
}
.filter-wrapper-status{
    width: 216px;
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(157, 160, 166, 0.12);
}
.filter-wrapper-status label{
    margin-bottom: 0 !important;
}