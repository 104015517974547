.new-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 501px;  /* Fixed width */
      /* Fixed height */
    transform: translate(-50%, -50%);
    background: white;
    padding: 15px;
    border-radius: 10px;
    outline: none;
    /* box-shadow:0 .5rem 1rem rgba(0,0,0,.5); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 100000000000000000000;
}

.new-modal-overlay {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
}


@media (max-width:530px) {
    .new-modal {
        width: 90%;
    }
}


.modal--open {
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.modal--open {
    animation: fadeIn 0.3s forwards;
}

.modal--close {
    animation: fadeOut 0.3s forwards;
}

.the-overlay {
    background-color: rgba(0, 0, 0, 0.90);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.overlay--open {
    opacity: 1;
}

.overlay--close {
    opacity: 0;
}

.new-modal-header {
    display: flex;
    justify-content: space-between;
}

.close-button {
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.new-modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;

}
.new-modal-container textarea{
    height: 100px;
    background-color: white !important;
}

.new-model-footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}