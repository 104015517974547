.checklist input,
.checklist input:focus,
.checklist input:hover {
  outline: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.new-btn {
  margin-right: 10px;
  padding: 10px 12px;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  border-radius: 8px;
  color: #adadad;
  /* font-weight: 500; */
}

.new-btn:disabled {
  cursor: not-allowed;
  background-color: #e1e1e1;
}
.new-btn:disabled svg {
  cursor: not-allowed;
  fill: #777;
}

.checklist-page {
  /* min-height: 100vh; */
  background-color: white;
  margin: 10px 30px 50px;
}

.checklist input::placeholder {
  color: #adadad;
  font-weight: normal;
}

.checklist {
  width: 98%;
  margin: 0 auto;
  /* font-family: raleway, sans-serif; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header .checkbox-wrapper {
  display: flex;
  align-items: center;
}

.import-checklist input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.import-checklist input + label {
  cursor: pointer;
  padding: 12px;
}

.checklist input[type='checkbox'] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.group-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  background-color: #f3f4f5;
  padding: 12px 16px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25); */
}

.group-input input {
  flex-grow: 1;
  border: 1px solid #ccc;
  margin-right: 10px;
  background-color: inherit;
  /* padding: 10px; */
}

.group-input input:focus,
.group-input input:hover {
  flex-grow: 1;
  background-color: #f3f4f5;
}

.group-input span {
  color: #777;
  font-size: 14px;
}

.checklist-group {
  border-radius: 8px;
  margin-bottom: 10px;
}

.group-header {
  display: flex;
  align-items: center;
  padding: 12px 16px !important;
  background-color: #f3f4f5;
  border-radius: 8px !important;
  /* cursor: grab; */
}

.drop-area {
  background-color: #f3f4f5;
  border-radius: 20px;
  height: 64px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0px;
}

.hide-drop-area {
  opacity: 0;
  overflow: hidden;
}

.group-header input[type='checkbox'] {
  margin-right: 10px;
}

.group-name {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
}

.group-name-input {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
  background-color: #f3f4f5 !important;
}

.task-count {
  margin-right: 20px;
}

.group-actions {
  display: flex;
  align-items: center;
}

.group-actions button {
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.group-actions button:not(:last-child) svg {
  color: #d9314a;
}

.task-list {
  background-color: white;
  transition: max-height 0.3s ease;
  font-size: 14px;
}

.task-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid #e7e7e7;
}

.task-input input {
  flex-grow: 1;
  background-color: white;
  padding-left: 0;
  padding-left: 0;
  padding: 10px;
}

.task-input input:focus {
  background-color: white;
}

.task-input span {
  color: #777;
}

.task-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 300;
  font-size: 14px;
  color: black;
  /* cursor: grab; */
}

.task-item:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
  margin: 5px 0;
}

.task-item .task-text {
  flex-grow: 1;
}

.task-item input {
  flex-grow: 1;
  padding: 10px;
  padding-left: 0 !important;
  margin-right: 10px;
  outline: 0;
  border: 0;
  background-color: white;
  border-radius: 0;
  color: black;
}

.task-item input:focus {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  outline: 0;
  border: 0;
  background-color: white;
  border-radius: 0;
}

.delete-task {
  background: none;
  border: none;
  cursor: pointer;
  color: #d9314a;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.pagination div {
  display: flex;
  align-items: center;
}

.pagination span {
  margin: 0 5px;
}

.checklist-preview-title {
  margin-top: 10px;
  color: #ed1b1b;
  background-color: rgba(237, 27, 27, 0.16);
  padding: 8px 12px;
  border-radius: 8px;
}

.checklist-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
}

.checklist-header p {
  margin-bottom: 0;
  color: #999999;
  font-weight: normal;
}

.adjust-group {
  margin-bottom: 10px;
  padding: 10px 15px;
  cursor: auto;
}

.checklist-header img {
  cursor: pointer;
}

.checklist-select select {
  background-color: white !important;
  padding: 2px 10px;
  width: 120px;
  color: #999999 !important;
  font-weight: 500 !important;
  height: 30px !important;
  font-size: 14px;
}

.checklist-select-active select {
  background-color: white !important;
  padding: 2px 10px;
  width: 110px;
  color: black !important;
  font-weight: 500 !important;
  height: 30px !important;
  font-size: 14px;
}

.checklist-main-search {
  width: 267px;
  border: 1px solid #e7e7e7;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}

.checklist-main-search img {
  margin-left: 10px;
  width: 18px;
}

.checklist-main-search input {
  border: none !important;
  outline: none !important;
  height: 40px;
  flex-grow: 1;
}

.checklist-main-search input::placeholder {
  color: black;
}
