.content-wrapper{
    min-height: calc(100vh - 56px) !important;
 }
.prof_img:hover{
    cursor:'pointer'
}

/* YourComponent.css */

.image-label {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  top: 10%;
  right: 0;
  padding:0;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: red; /* Set the color you desire for the pencil icon */
  opacity: 0; /* Initially hide the pencil icon */
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.image-label:hover .edit-icon {
  opacity: 1; /* Show the pencil icon on hover */
}
.img-company:hover{
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

@media only screen and (max-width:495px){
    .card-container{
        margin-top: 100px;
    }
}