/* Search Button */
.search .dropbtn {
  background-color: #fff;
  /* color: white; */
  padding: 8px;
  padding-left: 16px !important;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: start;
}

/* Search button on hover & focus */
.search:hover .dropbtn,
.dropbtn:focus {
  background-color: #f1f1f1;
}

/* The search field */
#myInput {
  box-sizing: border-box;
  // background-image: url('searchicon.png');
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

/* The search field when it gets focus/clicked on */
/* #myInput:focus {outline: 3px solid #ddd;} */

/* The container <div> - needed to position the search content */
.search {
  /* position: relative; */
  /* display: inline-block; */
  /* overflow: hidden; */
  width: 100%;
}

/* Search Content (Hidden by Default) */
.search-content {
  margin-top: 10px;
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 5px 5px #d6dadd60 !important;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1;
}

/* Links inside the search */
.search-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of search links on hover */
.search-content a:hover {
  background-color: #f1f1f1;
}

/* Show the search menu (use JS to add this class to the .search-content container when the user clicks on the search button) */
.show {
  display: block;
}

.search-list {
  /* background-color: white !important; */
  max-height: 20em;
  overflow: scroll;
}

.container {
  position: relative;
  min-width: 100px !important;
  min-height: 85px !important;
  max-height: 85px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pill:hover .image {
  opacity: 0.3;
}

.pill:hover .middle {
  opacity: 1;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 1em;
  right: 0.7em;
  display: inline;
  /* transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
  text-align: center;
}

.floorRow:hover {
  visibility: visible;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 5px #ededed;
}

#bin_icon_hide {
  visibility: hidden;
}

.floorRow:hover #bin_icon_hide {
  visibility: visible;
}

.drop-zone {
  max-width: 100%;
  height: 200px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #555566;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.list_hover:hover {
  background-color: #F8F8F8 !important;
  cursor: pointer !important;
}



