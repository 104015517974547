.leaflet-container {
  background-color: transparent !important;
  height: 100vh;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 3px solid #eeeeee !important;
  background-clip: padding-box;
}
.leaflet-top .leaflet-control {
  margin-top: 1.5em !important;
}

.leaflet-left .leaflet-control {
  margin-left: 0 !important;
}

.select {
  color: black !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
.select-asset {
  display: block;
  width: 100%;
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
  border-radius: 10px;
  height: 40px;
  padding: 5px 10px;
}

.my-cards {
  border-radius: 8px !important;
  box-shadow: 5px 5px 5px #d6dadd60, -8px -8px 8px white !important;
  background-color: #f8f8f8;
  border: none !important;
}

.unmarked-assets {
  cursor: pointer;
  background: #ffffff59;
  box-shadow: 3px 3px 3px rgba(214, 218, 221, 0.473), -4px -4px 4px white !important;
  border: none;
  border-radius: 5px;
}
.unmarked-assets-clicked {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: rgba(246, 56, 85, 0.137) 3px 3px 4px inset,
    rgba(255, 255, 255, 0.4) -4px -4px 4px inset;
  color: #f63854 !important;
}
