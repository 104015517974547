.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: 'Arial', sans-serif;
}

.not-found-title {
  font-size: 6rem;
  color: #ff6b6b;
  margin: 0;
}

.not-found-subtitle {
  font-size: 1.5rem;
  color: #333;
  margin: 0.5rem 0;
}

.not-found-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

.not-found-link {
  font-size: 1rem;
  color: white;
  background-color: #ff6b6b;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #ff4757;
  color: white;
}
