.assetForm {
  margin: 60px auto 0;
  width: 65%;
}

@media (max-width :700px) {
  .assetForm {
    width: 85%;
  }
}

.assetForm .seletions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.locationDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width :500px) {
  .assetForm .seletions {
    flex-direction: column;
  }

  .assetForm .seletions>div {
    width: 100%;
  }
}

.locTitle {
  font-size: 20px;
  margin-bottom: 30px;
}

.assetInfo h3 {
  font-size: 20px;
  margin-bottom: 30px;
}

.controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.control {
  width: 48%;
  margin-bottom: 20px;
}

.control>div {
  width: 100%;
}

.controlReactSelect {
  width: 55%;
  margin-bottom: 20px;
}

.controlReactSelect > div {
  width: 100%;
}

.inputFieldSpec {
  width: 100%;
  height: 100px;
  resize: both;
  overflow: auto;
  overflow-wrap: break-word;
  line-height: 100px; 
  text-align: left;
  vertical-align: top;
  padding: 8px;
  white-space: pre-wrap;
}

@media (max-width :500px) {
  .locTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .assetInfo h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .controls {
    align-items: center;
    justify-content: center;
  }

  .control {
    width: 100%;
    margin-bottom: 20px;
  }
  .controlReactSelect {
    width: 100%;
    margin-bottom: 20px;
  }
}

.control span {
  color: red;
}

.control p {
  margin-bottom: 5px;
}

.control input {
  width: 100%;
  padding: 10px 15px;
  outline: 0;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  box-shadow: inset 0 0 4px #e4e4e4;
  border-radius: 8px;
}

.textarea p {
  margin-bottom: 5px;
}

.textarea textarea {
  padding: 10px 15px;
  width: 100%;
  outline: 0;
}

.label {
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.titleAssetDocuments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.titleAssetDocuments h3 {
  margin-bottom: 0;
}

.titleAssetDocuments button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.titleAssetDocuments button svg {
  font-weight: 900 !important;
}

.table {
  width: 100%;
  border: none;
  border-collapse: separate;
  border-spacing: 0 10px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  padding: 10px;
  background: #f8f8f8 !important;
  overflow: hidden;
  border-radius: 8px !important;
}

.table thead {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  background-color: white !important;

}

.table thead td {
  padding: 10px !important;
  font-weight: normal !important;
  color: rgb(168, 168, 168);
}

.table tbody tr td {
  padding: 5px 10px
}

.table tbody tr:hover {
  background-color: white !important;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

td:first-child {
  border-right-style: solid;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.controlsSp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.controlSp {
  width: 100%;
  outline: 0;
}
.controlSpSpec {
  width: 90%;
}

.controlSp input {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  outline: 0;
}

.controlSpBtn {
  height: 50px;
  padding: 13px !important;
  display: block;
  color: #f63854 !important;
  background-color: white !important;
  border: 1px solid #e6e6e6 !important;
  margin-top: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlSpBtn:hover {
  background-color: #f3c1c9 !important;
}

@media (max-width:500px) {
  .titleAssetDocuments {
    margin-bottom: 20px;
  }

  .titleAssetDocuments h3 {
    font-size: 18px;
  }

  .controlsSp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }

  .controlSp {
    width: 40%;
  }

  .controlSp input {
    height: 35px;
    font-size: 12px;
  }

  .controlSp input::placeholder {
    font-size: 12px;
  }

  .controlSpBtn {
    height: 35px;
    padding: 8px !important;
  }
}



.assetDeprication h3 {
  margin-bottom: 15px;
}

.assetDeprication p {
  width: fit-content;
  margin-left: auto;

}

.assetDeprication input {
  --inactive-bg: #cfd8dc;
  --active-bg: #F63854;
  --size: 1rem;
  appearance: none;
  width: calc(var(--size) * 2.2);
  height: var(--size);
  display: inline-block;
  border-radius: calc(var(--size) / 2);
  cursor: pointer;
  background-color: var(--inactive-bg);
  background-image: radial-gradient(circle calc(var(--size) / 2.1), #fff 100%, #0000 0), radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
  background-repeat: no-repeat;
  background-position: calc(var(--size) / -1.75) 0;
  transition: background 0.2s ease-out;
}

.assetDeprication input:checked {
  background-color: var(--active-bg);
  background-position: calc(var(--size) / 1.75) 0;
}

.changecontrolsGrid .controls {
  display: block;
}

.changecontrolsGrid .controls .control {
  width: 40%;
}

.changecontrolsGrid .controls .control:nth-child(2) {
  width: 55%;
}

.wraperSelect {
  display: flex;
}

.wraperSelect select {
  width: 40%;
  color: #444 !important;
  font-weight: normal !important;
  cursor: pointer;

}

.wraperSelect select option {
  color: black;
}

.changecontrolsGrid .controls .control:nth-child(3) {
  width: 55%;
}

.changecontrolsGrid .controls .control:nth-child(3)>div {
  width: 100%;
}


.spareItem {
  padding: 10px;
  font-size: 14px;
  margin: 10px 0;
  background-color: #f1f1f1;
  cursor: pointer;
}

.scrollX {
  overflow-x: scroll;
}

@media (max-width :700px) {

  .assetDeprication h3 {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .changecontrolsGrid .controls .control {
    width: 100%;

  }

  .changecontrolsGrid .controls .control input {
    height: 35px;
    font-size: 12px;
  }

  .changecontrolsGrid .controls .control>div {
    width: 100%;
  }

  .controlReactSelect {
    width: 100%;
  }

  .changecontrolsGrid .controls .control:nth-child(2) {
    width: 100%;
  }

  .changecontrolsGrid .controls .control:nth-child(2) input {
    border-radius: 0;
  }

  .wraperSelect {
    display: flex;
  }

  .wraperSelect select {
    height: 35px;
    font-size: 12px;
    border-radius: 0;

  }

  .wraperSelect select option {
    color: black;
  }

  .changecontrolsGrid .controls .control:nth-child(3) {
    width: 100%;
  }

}

.depTab {
  background-color: #eeeeee;
  border-radius: 0;
  color: #808080;
}

.cardWraper {
  border-style: solid !important;
  border-color: #eeeeee !important;
  border-width: 3px !important;
  border-radius: 0.7em !important;
  box-shadow: none !important;
}

.filterSelect {
  display: block;
  width: 100%;
  background-color: #F8F8F8 !important;
  border: #F8F8F8 !important;
  border-radius: 10px;
  height: 40px;
  padding: 5px 10px;
  color: black !important;
  font-weight: normal !important;
}

.cardOfChart {
  padding: 5px !important;
}