.custom-datePicker-container {
    width: 260px;
    margin: auto;
  }
  
  .custom-datePicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .custom-datePicker-header h2 {
    margin: 0;
    font-size: 16px;
  }
  
  .custom-datePicker-header button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #E1E1E1;
    font-weight: bold;
  }

  .custom-datePicker-header button:hover {
    background-color: #E1E1E1;
  }

  .custom-datePicker-body{
    border: 1px solid #E1E1E1;
    border-radius: 12px;
    padding: 8px 12px 8px;
  }
  
  .custom-datePicker-day-labels {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    padding: 5px 0;
  }
  
  .custom-datePicker-day-label{
    font-weight: bold;
    font-size: 14px;
  }
  
  .custom-datePicker-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
  }
  
  .custom-datePicker-cell {
    padding: 6px 0;
    /* border: 1px solid #ddd; */
    cursor: pointer;
  }
  
  .custom-datePicker-cell.empty {
    border: none;
  }
  
  .custom-datePicker-cell.today {
    background-color: rgba(54, 56, 60, 0.5);
    color: white;
    border-radius: 8px;
  }
  
  .custom-datePicker-cell.selected {
    background-color: #36383C;
    color: white;
    border-radius: 8px;
  }
  

  .custom-datePicker-cell.disabled {
    color: #ccc;
    cursor: auto;
  }