// thead{
//     border-radius: 8px !important;
//     background-color: white !important;
// }
// th{
//     font-weight: normal !important;
//     color: rgb(168, 168, 168);
// }
// td, th {
//     border: solid 1px #000;
//     border-style: solid none;
//     padding: 0.5em !important;
//     background-color: transparent;
    
    
// }
// td:first-child, th:first-child {
//     border-left-style: solid;
//     border-top-left-radius: 8px; 
//     border-bottom-left-radius: 8px;
//     padding-left: 1em !important;
// }

// td:last-child, th:last-child {
//     border-right-style: solid;
//     border-bottom-right-radius: 8px; 
//     border-top-right-radius: 8px; 
// }

// // .table{
// //     overflow: hidden;
// // }

// // table tbody tr:hover {
// //     visibility: visible;
// //     background-color: white !important;
// //     border-radius: 8px !important;
// //     box-shadow: 0px 3px 5px #ededed;
// //     // background-color: red;
// // // 
// //  }

//  #bin_icon_hide{
//     visibility: hidden;
    
//  }

//  table tbody tr:hover #bin_icon_hide{
//     visibility: visible;
//     // background-color: red;
//  }

//  .table-action-hide {
//     text-align: right;
// }
// .table-action-hide a {
//     opacity: 0;
// }

// .table-action a, .table-action-hide a {
//     display: inline-block;
//     margin-right: 5px;
//     color: #666;
// }
// .table-action a:hover, .table-action-hide a:hover {
//     color: #333;
// }
// .table-action a:last-child, .table-action-hide a:last-child {
//     margin-right: 0;
// }
// .table-footer{
//     background-color: white !important;
//     border-radius: 8px !important;
// }

// ul{
//     margin-bottom: 0px !important;
// }
// .btn-group{
//     background-color: #f8f8f8 !important;
//     border-radius: 8px !important;
// }
// .btn-group2{
//     background-color: #f8f8f8;
// }


// img {
//     display: none;
//     max-width: 100%;
//     height: auto;
//   }
  
//   // Define styles for the text
//   p {
//     display: none;
//     font-size: 16px;
//     margin-bottom: 16px;
//   }
  
  // Define styles for the print preview
  @media print {
    .qr-print {
      display: block;
    }
    .qr-print div {
      display: block;
      font-size: 12pt;
      margin-bottom: 0;
    }
  }

  .associatedHead {
    max-width: 100% !important;
  }
  .pill:hover .image {
    opacity: 0.3;
  }
  
  .pill:hover .middle {
    opacity: 1;
  }
  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 1.7em;
    right: 0.7em;
    display: inline;
   transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%); 
    text-align: center;
  }
  
  .floorRow:hover {
    visibility: visible;
    background-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0px 3px 5px #ededed;
  }
  