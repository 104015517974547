.edit-wo-overview .card-body {
  padding: 20px 12px;
}

.edit-wo-overview .loc-subloc-wrapper {
  display: flex;
  gap: 16px;
}

.edit-wo-overview .asset-meter-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
}

.edit-wo-overview .cat-subcat-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
}

.edit-wo-overview .pr-due-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.edit-wo-overview .MuiSwitch-thumb {
  height: 15px;
  width: 15px;
}

.edit-wo-overview .MuiSwitch-track {
  height: 10px;
  width: 30px;
}

.log-tasks-radio-wrapper .MuiButtonBase-root {
  padding: 0;
  margin-right: 8px;
}

/* AddTime.css */
.time-container {
  display: flex;
  align-items: center;
  // border: 1px solid #E1E1E1;
  border-radius: 8px;
  // padding: 8px;
  justify-content: space-between;
  width: fit-content;
}

.time-container .input-container {
  display: flex;
  align-items: center;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 16px;
}

.time-container .input-container input[type="date"],
.time-container .input-container input[type="datetime-local"] {
  border: none !important;
  outline: none !important;
  font-size: 16px !important;
  background-color: white !important;
  box-shadow: none !important;
}


.time-container input[type="number"],
.time-container input[type="number"]:hover,
.time-container input[type="number"]:focus {
  width: 50px;
  font-size: 16px;
  outline: none !important;
  border: none !important;
  font-size: 16px !important;
  background-color: white !important;
  box-shadow: none !important;
  margin-right: 3px;
}
.time-container span{
  color: #9DA0A6;
}


.time-container input[type="number"]::-webkit-inner-spin-button,
.time-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.time-container .add-time-btn {
  background-color: #F8F9F9;
  color: #000;
  border: 1px solid #E1E1E1;
  padding: 8px 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}

.time-container .add-time-btn:hover {
  background-color: #E1E1E1;
}
