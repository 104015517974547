.login-logo-check-email{
  display :flex;
  justify-content :center
}
.login-logo-check-email img {
    width:150px;
    height: 130px;
}
.reset-pass-card{
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.7em !important;
    box-shadow: none !important;
}

.token-wrapper{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.token-image{
  width: 100%;
  object-fit: contain;
  height: 400px;
}
.token-content{
  margin-bottom: 5px !important;
  color: #999;
  text-align: center;
}