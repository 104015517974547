.nav-link {
  /* padding-left: 0.7em !important;
  padding-right: 0.7em !important; */
  overflow-wrap: break-word;
}

.nav-link .sb-item {
  font-size: small !important;
  font-weight: 500;
}

.nav-sidebar > .nav-item {
  width: 100%;
}

.notification {
  position: relative;
  text-align: right;
  display: inline-block;
}

.notification .notification-menu.active {
  display: block;
}

.notification:hover .icon-button {
  background: transparent;
}

.notification img {
  padding: 10px 0;
}

/* .notification-menu {
  position: absolute;
  top: 30px;
  left: -150px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(214, 218, 221, 0.3764705882) !important;
  padding: 6px;
  list-style: none;
  width: 320px;
  text-align: left;
  border-radius: 5px;
  z-index: 1;
} */

.notification-menu li {
  padding: 6px;
}

.notification-menu .avatar {
  padding: 2px 0;
}

.notification-menu h3 {
  font-size: 15px;
  margin: 0 0 5px 0;
  display: inline;
}

.notification-menu p {
  margin-bottom: 0;
  font-size: 14px;
}

.notification-menu {
  width: 320px;
  /* height: auto; */
  /* max-height: 700px; */
  /* overflow-y: auto; */
  z-index: 1;
}

.notification-menu a {
  background-color: #f8f8f8;
  color: black;
  display: block;
  padding: 8px;
  text-decoration: none;
  border-radius: 5px;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #4caf50;
  color: white;
}

.sidebar-light-danger .nav-sidebar > .nav-item > .nav-link.active {
  color: var(--primary-color) !important;
  background-color: var(--lighter-blue) !important;
}

.nav-pills .nav-link {
  border-radius: 0.3em !important;
  box-shadow: none !important;
}

.badge {
  font-size: 60% !important;
  padding-left: 3px;
  padding-right: 3px;
}

.badge-danger {
  color: #fff;
  background-color: var(--primary-color);
}

.brand-link-styles {
  border-bottom: none !important;
  font-weight: bold;
}

.company-name-styles {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 173px;
}

.report-styles {
  font-size: 0.9em !important;
}

.sidenav {
  width: 250px;
  transition: width 0.3s ease-in-out;
  height: 100vh;
  background-color: rgb(10, 25, 41);
  padding-top: 28px;
}

.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 60px;
}

.sideItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
  overflow: hidden;
}

.linkText {
  padding-left: 16px;
}

.sideItem:hover {
  background-color: rgba(36, 79, 125, 0.1098039216);
}

.menuBtn {
  align-self: flex-start;
  justify-self: flex-end;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}

.nav-styles {
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-bottom: none;
}

.notification-styles {
  margin-right: 20px;
  border: none;
  outline: none;
}

.notification-icon-styles {
  outline: none;
  border: none;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
}

.icon-button__badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--primary-color);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon-button:hover {
  cursor: pointer;
  background-color: var(--primary-color) !important;
}

.icon-button:active {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #cccccc;
}

.profile-link {
  font-weight: bold;
  color: rgb(68, 68, 68);
}

.profile-section {
  padding-top: 2px;
}

.main-layout-div {
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
}

.main-sidebar {
  position: fixed !important;
}/*# sourceMappingURL=layout.css.map */