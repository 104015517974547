.inline-main {
  background-color: #f63854 !important;
}
.inline-carousel-item {
  background-color: transparent;
  border-radius: 0.7em;
}
.inline-maintain-logo {
  width: 100%;
}
.inline-login-box {
  background-color: white;
  // padding: 2.5em 2.5em 5em;
  border-radius: 0.7em;
  width: 25em;
}
.inline-error-box {
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.inline-username {
  // background-color: #e0e0e05d;
  background-color: white !important;
  border-radius: 0.5em;
  border: none;
  height: 3em;
}

.inline-password {
  // background-color: #e0e0e05d;
  background-color: white !important;
  border: none;
  height: 3em;
  border-radius: 0.5em 0 0 0.5em;
}
.inline-show-password {
  background-color: #e0e0e05d;
  // border-radius: 0 0.5em 0.5em 0;
  border-radius: 12px 0 0 12px !important;
  padding-left: 15px;
  border: none;
  height: 3em;
}
.inline-show-password-right {
  border-radius: 0 12px 12px 0 !important;
  padding-left: 12px;
  padding-right: 15px;
}
.password-icon-left {
  padding-right: 15px !important;
}
.inline-forgot-password {
  color: #9b9b9b;
}
.inline-login-btn {
  color: white;
  background-color: #36383C !important;
  border-radius: 0.5em;
  height: 3em;
}
.inline-login-btn:hover {
  color: white !important;
  background-color: #4a4c4e !important;
}
.get-fox-btn {
  color: white;
  background-color: #f63854 !important;
  border-radius: 0.5em;
}
.get-fox-btn:hover {
  color: white !important;
  background-color: #ff5f77 !important;
}
.login-contact {
  text-decoration: underline;
  color: #f63854;
  font-weight: 600;
  cursor: pointer !important;
}
.inline-footer {
  color: #9b9b9b;
}
