#wo-tabs .nav-link {
    background-color: #E1E1E1;
    border: 1px solid transparent !important;
    color: #9DA0A6;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    // border-radius: 4px !important;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 14px;
}

#wo-tabs .nav-link.active {
    // background-color: white !important;
    background-color: #F8F9F9 !important;
    color: var(--primary-color);
    border-top: 1px solid #E1E1E1 !important;
    border-right: 1px solid #E1E1E1 !important;
    border-left: 1px solid #E1E1E1 !important;
    border-bottom: 1px solid transparent !important;
}

.wo-page {
    background-color: #F8F9F9;
    padding: 20px;
    border-radius: 8px;
}