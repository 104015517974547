.summaryCard {
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(145, 158, 171, 0.16);
  min-width: 160px;
  min-height: 80px;
  background-color: #F2F2F3;
  padding: 10px;
  margin-bottom: 20px;
}
.summaryCard h3 {
  font-size: 32px;
  color: #4a4c50;
  margin-bottom: 15px;
  font-weight: 600;
}
.summaryCard h4 {
  font-size: 14px;
  color: #4a4c50;
  font-weight: 600;
  margin-bottom: 0px;
}
.summaryCard span {
  display: inline-block;
  height: 9px;
  width: 9px;
  border-radius: 50%;
}
