@media screen and (max-width:416px) {
    .card-box{
        padding-left: 1px!important;
        padding-right: 0px!important;
        width:357px!important
    }
    .footer{
        width:376px!important
    }
    td{
        padding:1px!important
    }
}