.select{
position: relative;
width: 48%;
margin-bottom: 10px;
}
@media (max-width :500px ) {
.select{
width: 100%;
}

}
.overlay{
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0000001);
    z-index: 10;
}
.select p{
    margin-bottom: 5px;
}
.select p span{
    color: red;
}
.chooseItem{
    width: 100%;
    padding: 10px 0px 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    color: #444;
    height: 35px;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
}
.chooseItemWithoutBorder{
    width: 100%;
    padding: 10px 0px 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #444;
    height: 35px;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
}

.rotateIcon {
    transform: rotate(180deg);
}
.listItems{
  border: 1px solid #E1E1E1;
  width: 100%;
  border-top: 0;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 65px;
  z-index: 10;
  background-color: #fff;
  border-radius: 5px;
  z-index: 20;
  
}

.listItems input{
width: calc(100% - 4px) !important;
margin: 3px 2px 0;
padding: 5px !important;;
border: 1px solid  #9c9c9c;
border-radius: 5px !important;;
position: sticky;
top:5px;
left: 0;
background-color: white;
z-index: 1000;
margin-bottom :10px;
}
.listItems input:focus{
    outline: 0;
    border-color: #80bdff;
}
.select .listItems li:first-child{
    margin-top: 10px;
}
.select .listItems li{
   padding: 5px 10px ;
   cursor: pointer;
   font-size: 14px;
   margin: 2px 0;
}
.select .listItems li:hover{
    color: white;
    background-color: #007bff;
}
.noResult{
    padding: 5px 10px ;
    cursor:default;
    font-size: 16px;
}
.noResult:hover {
color: black;
background-color: white;

}

.chossenClass{
    color: white;
    background-color: #007bff;
}

