@media print {
    .qr-print {
      display: block;
    }
    .qr-print div {
      display: block;
      font-size: 12pt;
      margin-bottom: 0;
    }
    .card-body{
        display: block !important;
    }
  }
