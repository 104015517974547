/* src/CustomCalendar.css */

.custom-calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-left: 1px solid #E1E1E1;
    margin-bottom: 30px;
  }
  
  .custom-calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px
  }
  .custom-calendar-header .count-service{
    font-size: 14px;
    font-weight: normal;
    color: #36383C;
  }
  .custom-calendar-header .count-service span{
    font-weight: bold;
    color: var(--primary-color);
    margin-right: 5px;
  }
  
  .custom-calendar-header h2 {
    margin: 0;
  }
  
  .custom-calendar-header button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-calendar {
    gap: 1px;
    width: 100%;
  }
  
  .custom-calendar .custom-calendar-header-2,
  .custom-calendar .custom-calendar-row-2 {
    display: grid;
    grid-template-columns: 60px repeat(7, minmax(100px, 1fr)); /* Adjust column width */
    position: relative;
  }
  .custom-calendar .day-view-row{
    grid-template-columns: 60px repeat(1, 1fr);
    position: relative;
  }

  .custom-calendar .custom-calendar-cell {
    border-left: 2px solid #F8F9F9;
    border-bottom: 2px solid #F8F9F9;
    padding: 2px;
    /* text-align: center; */
    font-size: 0.9rem;
    height: 80px;
  }

  .custom-calendar .custom-calendar-row-2:first-of-type{
    border-top: 1px solid #E1E1E1;
  }

  .custom-calendar .custom-calendar-header-2{
    height: 30px;
  }
  
  .custom-calendar-body{
    overflow: auto;
    height: 70vh;

  }
  .custom-calendar .time-header,
  .custom-calendar .time-cell {
    background-color: white;
    font-weight: bold;
  }

  .custom-calendar .time-cell{
    font-size: 12px;
    font-weight: 400;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-calendar .time-cell span{
    position: absolute;
    top: -10px;
    z-index: 1000;
  }
  .custom-calendar .custom-calendar-row-2:first-of-type .time-cell span{
    display: none;
  }

  .custom-calendar .time-header:first-of-type{
    border: none;
    height: 30px;
  }

  .custom-calendar .custom-calendar-row-2:last-of-type .time-cell{
    border-bottom: 2px solid #F8F9F9;
  }
  
  .custom-calendar .day-header {
    color: #36383C;
    border: none;
    font-weight: 600;
    font-size: 12px;
    height: 30px;
    text-align: center;
  }
  
  .custom-calendar .day-header.today {
    border-bottom: 2px solid var(--primary-color);
  }
  
  .custom-calendar-event {
    padding: 2px 8px 2px 0px;
    margin: 2px 0;
    font-size: 14px;
    color: white;
    position: relative;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .custom-calendar-event span{
    display: block;
    width: 3px;
    height: 22px;
    background-color: var(--primary-color);
  }
  .custom-calendar-event p{
   margin-bottom: 0;
   padding-left: 12px;

   
   white-space: nowrap; /* Prevents text from wrapping */
   overflow: hidden; /* Hides the overflowed text */
   text-overflow: ellipsis; /* Adds ellipsis for truncated text */
   max-width: 100%; /* Ensures it doesn't exceed the width of its container */
  }
  
  .custom-calendar .show-more-btn {
    border: none;
    padding: 2px 4px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    color: #36383C;
  }



.task-planner-container-calendar{
    display: flex;
    /* justify-content: center; */
    border-top: 1px solid #E1E1E1;
    margin-top: 20px;
    margin-bottom: 10px;
}


.popup-content {
  position: absolute; /* Use absolute to align dynamically */
  background: white;
  padding: 10px;
  border-radius: 8px;
  width: 200px;
  z-index: 2; /* Ensure it stays on top */
  box-shadow: 0px 12px 32px 0px rgba(41, 43, 47, 0.16);
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    z-index: 1;
  }

  .popup-content ul{
    position: relative;
    z-index: 100000;
    max-height: 200px;
    overflow-y: scroll;
  }
  
  
  .close-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  

  .current-time-line {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: red; /* Customize the color */
    z-index: 1;
}

  /* CSS for full-width day view */
.full-width-day-view .custom-calendar-cell.full-width-cell {
  width: 100%; /* Make the day cell take the full width */
}

.full-width-header {
  width: 100%; /* Make the header for the day full width */
  text-align: center; /* Center the day header text */
}

.toggle-category{
  margin-top: 25px;
}

.toggle-category h4{
  font-size: 16px;
  font-weight: 600;
  color: #36383C;
}

.toggle-category .filter-wrapper-status{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}
.toggle-category .filter-wrapper-status .weekly-frequency-label{
  font-weight: 400;
  font-size: 14px;
}

.toggle-category .filter-wrapper-status > div{
  display: flex;
  align-items: center;
}

.toggle-category .cat-circle{
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}


