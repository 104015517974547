.custom-tooltip-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.custom-tooltip-box {
    position: absolute;
    bottom: 190%;
    /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 12px 13px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    z-index: 10000;
    box-shadow: 0px 12px 32px 0px rgba(41, 43, 47, 0.16);
}

.bill-disabled-input{
    bottom: 125%;
}
.custom-tooltip-box span{
    margin-left: 0px !important;
}

.custom-tooltip-box::after {
    content: '';
    position: absolute;
    top: 100%;
    /* Arrow points downward */
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    z-index: 10000;
}