.info-users  {
    margin-right: 10px !important;
    column-gap: 4px;
}
.info-users b {
    margin: auto;
}
.info-users svg {
    margin: auto;
}
.searc-head input{
    border-radius: 10px;
}
.assets{
    margin: 10px 30px 50px;
    min-height: 100vh !important;
    overflow-x: hidden !important;
}
@media screen and (min-width: 280px) and (max-width: 500px) {
 
    .h-1000{
        width: auto !important;
    }
    #modal-content{
        height: 95% !important;
    }
    #modal-body{
        overflow: scroll;
    }
    .prof_img{
        padding-top: 8px;
    }
}
@media (min-width: 576px) {
    .addnew{
        max-width: 800px !important;
    }
        
}