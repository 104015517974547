.tab-item {
  display: block;
  // padding: 30px !important;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.custom_tabs .nav-link {
  // padding: 10px 15px;
  border-radius: 5px;
  color: #555;
}

.custom_tabs .nav-link.active {
  background-color: var(--primary-blue);
  color: #fff;
  border-color: var(--primary-blue);
}

// .tab-item.summary-tab .nav-link {
//   background-color: #f0f8ff;
// }

// .tab-item.license-tab .nav-link {
//   background-color: #faebd7;
// }

// .tab-item.productivity-tab .nav-link {
//   background-color: #ffe4c4;
// }

// .tab-item.sustainability-tab .nav-link {
//   background-color: #e6e6fa;
// }

// /* Hover effects */
.custom_tabs .nav-link:hover {
  color: var(--primary-blue);
  background-color: var(--lighter-blue);
}
