
.meter-card{
  border-style: solid !important;
  border-color: #eeeeee !important;
  border-width: 3px !important;
  border-radius: 0.7em !important;
  box-shadow: none !important;
}

.select-meter{
  color: black !important;
  font-size: 16px !important;
  font-weight: normal !important;
  height: 40px !important;
  padding-left: 10px !important;
}

.trigger-div{
  width: fit-content;
  margin-left: auto;
}

.dropdown-menu-active{
  margin-top: 8px;
  padding: 0;
  display: block;
}

#file-input {
  display: none;
}

#file-input-label {
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 4%;
  font-weight: normal;
  margin-bottom: 0 !important;
}

.act-table{
  width: 100%;
  
}

.act-table thead td{
  padding:  3px 10px;
  font-weight: 500;
  font-size: 16px;
}
.act-table tbody td{
  padding: 3px 10px;
  font-weight: 400;
  font-size: 14px;
}
.act-table tbody tr{
  height: 66px;
}
.act-table tbody tr:hover{
  /* cursor: pointer; */
  visibility: visible;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 5px #ededed;
}

.assignedImage{
  border-radius: 50%;
  height: 40px;
  aspect-ratio: auto 40 / 40;
  width: 40px;
}
