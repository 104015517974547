.input-field {
    max-width: 200px !important;
}

.input-field>select {
    border: 2px solid #FFEBEE !important;
    height: 30px !important;
    padding: 2px !important;
    color: black !important
}

/* .erase-btn {
    margin-top: 31px;
} */

/* .erase-btn>Button {
    height: 30px !important;
} */

.date-input:hover {
    cursor: pointer !important;
}

.apexcharts-canvas{
    max-width: 100% !important;
    max-height: 100% !important;
}

#chart{
    max-width: 100% !important;
    max-height: 100% !important;
}

/* .apexcharts-legend{
    top: 15px !important;
    right: -5px !important;
} */

.erase-wrapper-analytics{
    height: 30px !important;

}
.erase-wrapper-analytics .erase-btn{
    height: 41px  !important;

}

.erase-wrapper-analytics button{
    height: 36px !important;
}