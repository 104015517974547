.assets {
    margin: 10px 30px 50px;
}

.newPlusButton:hover {
    background-color: #b6293e !important;
}

.newOutlineButton:hover {
    background-color: #E1E1E1 !important;
}

.assetsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.assetsTitle {
    font-size: 24px;
}

@media (max-width :550px) {
    .assetsTop {
        flex-direction: column;
    }

    .assetsTitle {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.assetActions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.assetActions input {
    padding: 10px;
    /* background-color: white; */
    outline: none;
    border: none !important;
    box-shadow: none;
}

.assetActions input:hover {
    background-color: transparent;
}

.assetActions input::placeholder {
    color: #9DA0A6;
}

.assetActions button {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #eee;
}

.assetActions button:hover:not(:first-of-type) {
    color: #F63854 !important;
    background-color: #FFCCD4 !important;
    border: 1px solid #F63854 !important;
}

.assetActions button:hover:first-of-type {
    background-color: #D9314A !important;
    color: white !important;
    border: 1px solid #D9314A !important;
}

.assetfaPlus {
    background-color: #F63854 !important;
    color: white !important;
    border: 1px solid #FF99A8 !important;
}

.assetfaPlus svg {
    color: white;
    font-weight: bold !important;
}

.assetfaPlus a,
.assetfaPlus span {
    display: block;
    padding: 6px 12px;
}

.assetFilter,
.assetEllipsis {
    color: #F63854 !important;
    background-color: white !important;
    border: 1px solid #E6E6E6 !important;
    padding: 8px 12px;
}

.assetFilter svg,
.assetEllipsis svg {
    color: red;
}

.assetActions button svg {
    font-size: 18px;
}

@media (max-width :550px) {
    /* .assetActions input{
        padding: 8px ;
        background-color: white;
        width: 150px;
    } */

    .assetActions button {
        margin-left: 10px;
    }

    .assetfaPlus a,
    .assetfaPlus span {
        padding: 4px 8px;
    }

    .assetFilter,
    .assetEllipsis {
        padding: 8px 8px;
    }

    .assetActions button svg {
        font-size: 15px;
    }
}


.imports {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 0px;
}

.imports div {
    background-color: white;
    color: red;
    padding: 8px 10px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imports div a {
    color: red;
}


.imports .Importfile input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.imports .Importfile input+label {
    font-size: 16px;
    font-weight: normal;
    color: red;
    cursor: pointer;
    margin-bottom: 0;
}



/* .imports div:not(:first-of-type){
    margin-left: 30px;

} */
.imports div:hover {
    background-color: rgba(255, 0, 0, 0.2);
}

.imports div svg {
    margin: 0 5px;
}

@media (max-width :895px) {
    .imports div {
        width: 30%;
    }
}

@media (max-width :895px) {
    .imports div {
        width: 48%;
        text-align: center;
    }
}

@media (max-width :550px) {
    .imports {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        flex-wrap: wrap;
    }

    .imports div {
        background-color: white;
        width: 45%;
        color: red;
        margin: 10px 0;
        text-align: center;
    }

    .imports div:not(:first-of-type) {
        margin-left: 0px;

    }

    .imports div:hover {
        background-color: rgba(255, 0, 0, 0.2);
    }

    .imports div svg {
        margin: 0 5px;
    }

}

@media (max-width :550px) {

    .imports div {
        background-color: white;
        width: 100%;
        color: red;
        margin: 10px 0;
        text-align: center;

    }
}

.billButton {
    border: 1px solid #E1E1E1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #36383C;
    padding: 10px 10px;
    position: relative;
    background-color: #F2F2F3;
}

.billButton:hover {
    background-color: none !important;
}

.billBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #D9314A;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}