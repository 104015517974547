
.login-page{
background-color: rgb(248, 248, 248) !important;
min-height: 380.391px;
margin: 0;
font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"HelveticaNeue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
text-align: left;
justify-content: flex-start;

}



.login-box{
box-sizing: border-box;
margin :5em !important;
margin-bottom: 0px !important;
border-style: solid !important;
border-color: #E0E0E0 !important;
background-color: white !important;
border-width: 3px !important;
padding: 2.8em;
// padding-bottom: 5rem !important;
border-radius: 0.7em;
@media screen and (max-width: 1350px ) and (max-height:550px)  {
margin :10px !important;
    
}
}
.login-logo{
// font-size: 2.1rem;/
// font-weight: 300;
margin-bottom: 0.9rem;
text-align: center;
width : 75%;
// padding-bottom:4rem;
}
.login-box-msg {
margin: 0;
// padding: 20px 20px 20px 20px;
margin-bottom:50px;
text-align: center;
margin-bottom: 0.5rem;
font-family: inherit;
font-weight: 500;
line-height: 1.2;
font-size:1.1rem;
color: inherit;
margin-bottom:1.2rem;

}
.email-reset {
display: block;
width: 100%;
// color :red;
// height: calc(2.25rem + 2px);
// padding: 0.375rem 0.75rem !important;
// font-size: 1rem;
// font-weight: 400;
// line-height: 1.5;
font-weight: 400!important;
display: inline-block;
margin-bottom: .4rem !important;
font-size:.9rem;
// color: #495057;
// background-color: #fff;
// background-clip: padding-box;
// border: 1px solid #ced4da;
// border-radius: 0.25rem;
// box-shadow: inset 0 0 0 transparent;
// transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.email-input{
    width: 100% !important;
height: calc(2rem + 2px) !important;
color: #495057 !important;
// background-color: #fff !important;
background-clip: padding-box;
border: 1px solid #ced4da !important;
border-radius: 0.25rem;
box-shadow: inset 0 0 0 transparent !important;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;

}
.input-group-email-reset{
    margin: 0px !important;
}
// @media screen (min-width:400px)
// {
    



// }