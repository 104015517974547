.tabs {
  display: flex;
  align-items: center;
  position: relative;
}

.tabs::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  bottom: 0px;
  background-color: #e1e1e1;
  z-index: 1;
}

.tabs button {
  margin-right: 3px;
  padding: 8px 16px;
  border: 1px solid #e1e1e1;
  background-color: white;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 1px 1px 0px 1px;
  z-index: 2;
  font-size: 14px;
}

.tabs button.inactive {
  background-color: #e1e1e1;
  color: #9da0a6;
}

.tabs button span {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
