.addAsset{
    margin-bottom: 30px;
}

.addAssetsTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 30px 0;
}
.AssetInfo{
    display: flex;
    align-items: center;
}
.AssetInfo img{
    width: 120px;
    height: 120px;

}
.AssetInfoContent{
    margin-left: 30px;
}
.AssetInfoContent p{
    color: #6c757d;
}

.addAssetsTitle{
    font-size: 18px;
}
.addAssetActions {
    display: flex;
    align-items: center;
    position: relative;
}


.addAssetActions button{
    margin-left: 20px;
    padding: 8px 10px !important;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #eee;

}

@media (max-width :550px ) {
    .AssetInfoContent{
        margin-left: 20px;
    }
    .AssetInfoContent p{
        color: #6c757d;
    }
    
    .addAssetsTitle{
        font-size: 16px;
    }
    
    
    .addAssetActions button{
        margin-left: 10px;
        padding: 5px 8px !important;
        border-radius: 5px;
    
    }
  }
  @media (max-width :450px ) {
    .addAssetsTop{
        flex-direction: column;
        margin: 10px 20px 0;
    }
    
    .AssetInfo img{
        width: 100px;
        height: 100px;
    
    }

    .addAssetsTitle{
        font-size: 12px;
    }
    
    
    .addAssetActions button{
        margin-left: 15px;
        margin-top: 15px;
    }
    .addAssetActions button:first-of-type{
        margin-left: 0px;
    }
  }
.addAssetActions .optionsOfAsset{
    background-color: white ;
    color:  #F63854;
    border: 1px solid #E6E6E6 ;
    font-size: 18px;
    padding: 8px 10px;
}
.addAssetActions .listOptions{
    border: 1px solid #E6E6E6;
    position: absolute;
    top: 52px;
    left: 20px;
    background-color: rgb(240, 240, 240);
    z-index: 100000;
}

.addAssetActions .listOptions li{
    padding: 8px 20px;
    cursor: pointer;
    font-size: 16px;
}
.addAssetActions .listOptions li:first-child{
    color: red;
}
.addAssetActions .listOptions li:nth-child(2){
    color: rgb(65, 132, 255);
}
.addAssetActions .listOptions li:hover{
    background-color: #eee;
}

.addAssetActions .addAssetSave{
    background-color: #F63854 ;
    color: white ;
    border: 1px solid #FF99A8 ;
    font-size: 16px;
}
.addAssetActions .addAssetBack{
    background-color: white ;
    border: 1px solid #E6E6E6 ;
    font-size: 16px;
}

.addAssetActions .addAssetSave:hover{
    background-color: #D9314A !important;
    color: white !important;
    border: 1px solid #D9314A !important;
}
.addAssetActions .addAssetBack:hover,
.addAssetActions .optionsOfAsset:hover{
    color: #F63854 !important;
    background-color: #FFCCD4 !important;
    border: 1px solid #F63854 !important;
}
.addAssetActions .addAssetBack a{
    color: #F63854 ;
}
@media (max-width :450px ) {
    .addAssetActions .optionsOfAsset{
        font-size: 14px;
        padding: 8px 10px;
    }
    .addAssetActions .addAssetSave{
        font-size: 12px;
    }
    .addAssetActions .addAssetBack{
        font-size: 12px;
    }
    
  }
.addAsset .form .list{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.addAsset .form .list::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #c7c7c7;
   bottom:-20px ;
}
.addAsset button{
    padding: 8px 10px;
    color: #6c757d;
    font-size: 14px;
    border-radius: 7px;
}
.addAsset .form .list button:not(:first-of-type){
margin-left: 10px;
}
.addAsset .form .list button:hover{
    color: #F63854;
    cursor: pointer;  
}
.addAsset .form .list .active{
    color: #F63854;
    background-color: #FFEBEE;
}

.assetPdf{
    width: auto;
    height: 50vh;
    margin: 2rem;
    font-size: large;
}
.assetrow{
    border-bottom: 1px solid gray;
    height: 3rem;
    text-align: start;
    padding-top: 0.5rem;
}
.pdfheader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 5px solid black;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-top: 30px;
}
.signature{
border-top: 1px solid black;
display: inline-block;
position: absolute;
width: 200px;
}


